import gql from 'graphql-tag'

const chatUnreadCount = gql`
  subscription($workspace_id: Float!) {
    chatUnreadCount(workspace_id: $workspace_id) {
      roomId
      unreadMsg
    }
  }
`

export default chatUnreadCount
