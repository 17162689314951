export default () => {
  const urlify = string => {
    const urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm
    const searchedLinks = string.match(urlRegex)

    if (!searchedLinks) return [string]

    const matchedLinks = searchedLinks.map(link => ({ plainText: link, urlName: (link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`) }))

    const replacedLinks = string.replace(urlRegex, 'hyperlink@123')
    const splittedString = replacedLinks.split('hyperlink@123')

    const newSplitted = []
    let ctr = 0; let ctrReplace = 0; let
      ctrSplitted = 0

    while (newSplitted.length !== matchedLinks.length + splittedString.length) {
      if (ctr % 2 === 0) {
        newSplitted.push(splittedString[ctrReplace])
        ctrReplace += 1
      } else {
        newSplitted.push({ type: 'url', label: matchedLinks[ctrSplitted].plainText, url: matchedLinks[ctrSplitted].urlName })
        ctrSplitted += 1
      }
      ctr += 1
    }

    return newSplitted
  }

  const emailify = string => {
    const emailRegex = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
    const searchedEmail = string.match(emailRegex)

    if (!searchedEmail) return [string]

    const replacedEmail = string.replace(emailRegex, 'email123Replaced')
    const splittedString = replacedEmail.split('email123Replaced')

    const newSplitted = []
    let ctr = 0; let ctrReplace = 0; let
      ctrSplitted = 0

    while (newSplitted.length !== searchedEmail.length + splittedString.length) {
      if (ctr % 2 === 0) {
        newSplitted.push(splittedString[ctrReplace])
        ctrReplace += 1
      } else {
        newSplitted.push({ type: 'email', label: searchedEmail[ctrSplitted] })
        ctrSplitted += 1
      }
      ctr += 1
    }

    return newSplitted
  }

  const phonelify = string => {
    const phoneRegex = /\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/gim
    const searchedPhone = string.match(phoneRegex)

    if (!searchedPhone) return [string]

    const replacedPhone = string.replace(phoneRegex, 'phone123Replaced')
    const splittedString = replacedPhone.split('phone123Replaced')

    const newSplitted = []
    let ctr = 0; let ctrReplace = 0; let
      ctrSplitted = 0

    while (newSplitted.length !== searchedPhone.length + splittedString.length) {
      if (ctr % 2 === 0) {
        newSplitted.push(splittedString[ctrReplace])
        ctrReplace += 1
      } else {
        const phoneNumber = searchedPhone[ctrSplitted].replace(/[^\d]/g, '')
        const fixNumber = phoneNumber.substring(0, 2) === '62' ? `0${phoneNumber.slice(2)}` : phoneNumber

        newSplitted.push({ type: 'phone', label: searchedPhone[ctrSplitted], phone: fixNumber })
        ctrSplitted += 1
      }
      ctr += 1
    }

    return newSplitted
  }

  const encodeComment = string => {
    const doc = new DOMParser().parseFromString(string, 'text/html')
    const arr = [...doc.body.childNodes].map(child => child.outerHTML || child.textContent)

    const comment = []
    arr.forEach(el => {
      if (el.startsWith('<div data-type=')) {
        const id = el.match('data-id="(.*?)">')[1]
        const label = el.match('<span class="mention-text">(.*?)</span>')[1]
        const type = el.match('data-type="(.*?)"')[1]
        comment.push({
          id: +id,
          type,
          label,
        })
      } else if (el === '<br>') {
        comment.push(el)
      } else {
        const elReplaced = el.replace(/<\/?[^>]+(>|$)/g, '')
        const linkedEl = urlify(elReplaced)
        linkedEl.forEach(linked => {
          comment.push(linked)
        })
      }
    })

    const emailComment = []
    const phoneComment = []

    comment.forEach(rawComment => {
      if (typeof rawComment !== 'object') {
        const linkedEl = emailify(rawComment)

        emailComment.push(...linkedEl)
      } else emailComment.push(rawComment)
    })
    emailComment.forEach(rawComment => {
      if (typeof rawComment !== 'object') {
        const linkedEl = phonelify(rawComment)

        phoneComment.push(...linkedEl)
      } else phoneComment.push(rawComment)
    })

    return JSON.stringify(phoneComment)
  }

  const findMention = string => {
    const encodedComment = JSON.parse(encodeComment(string))

    const mention = {
      user_id: [],
      team_id: [],
    }

    encodedComment.filter(el => typeof el === 'object').forEach(el => {
      if (el.type === 'user') mention.user_id.push(el.id)
      if (el.type === 'team') mention.team_id.push(el.id)
    })

    return mention
  }

  return {
    encodeComment,
    findMention,
  }
}
