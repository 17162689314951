import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"userTeamAutoSuggest",attrs:{"placeholder":"Cari pengguna atau tim...","label":_vm.label,"outlined":"","dense":"","deletable-chips":"","chips":"","attach":_vm.attach,"return-object":"","clearable":"","multiple":_vm.multiple,"loading":_vm.loadingSearchUsersTeams,"items":_vm.options,"item-value":"id","item-text":"name","small-chips":"","hide-details":"auto","no-filter":"","rules":_vm.inputRules,"no-data-text":"Pengguna atau tim tidak ditemukan"},on:{"update:search-input":_vm.onSearch},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[(data.item.photo)?_c(VImg,{attrs:{"src":data.item.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(data.item.name)))])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(data.item.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(data.item.email))]),_c(VListItemSubtitle,[_vm._v(_vm._s(data.item.phone_number))])],1)]}},{key:"selection",fn:function(data){return [_c(VChip,{staticClass:"mt-1",attrs:{"color":data.item.team ? 'info' : 'primary',"small":"","close":""}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}]),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }