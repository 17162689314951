import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selection)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"text-caption"},[_vm._v("Visibilitas")]),_c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"8px","content-class":"vispicker"},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c(VSheet,_vm._g(_vm._b({staticClass:"mt-2 d-flex align-center justify-center",attrs:{"width":"60","height":"40","outlined":"","rounded":""}},'v-sheet',Object.assign({}, menu.attrs, tooltip.attrs),false),Object.assign({}, menu.on, tooltip.on)),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.selection.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.selection.name))])])]}}],null,false,2383020098)},[_c(VCard,{attrs:{"max-width":"300px"}},[_c(VList,{attrs:{"nav":""}},[_c(VListItemGroup,{model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},_vm._l((_vm.visibilityOptions),function(item){return _c(VListItem,{key:item.id,staticClass:"d-flex align-start py-1 mb-1",attrs:{"value":item}},[_c(VIcon,{staticClass:"mt-1",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('div',{staticClass:"ml-2 d-flex flex-column mr-2"},[_c('span',{staticClass:"text-subtitle-2 primary--text"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"mt-1 text-caption"},[_vm._v(_vm._s(item.description))])])],1)}),1)],1)],1)],1)],1),_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v("Yang Dapat Melihat")]),_c('div',{staticClass:"d-flex mt-2 align-center"},[_c('limit-elements',{staticClass:"v-avatar-group",class:_vm.rootThemeClasses,attrs:{"elements":_vm.selection.id === 5 ? _vm.selectUsers : _vm.visibilities.includes(_vm.selection.id) ? _vm.searchUsersTeamsResults.users : [],"limit":5},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"mb-1",attrs:{"size":"38"}},'v-avatar',attrs,false),on),[(data.photo)?_c(VImg,{attrs:{"src":data.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(data.name)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.name))])])]}},{key:"others",fn:function(ref){
var data = ref.data;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"mb-1",attrs:{"size":"38"}},'v-avatar',attrs,false),on),[(data.photo)?_c(VImg,{attrs:{"src":data.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(data.name)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.name))])])]}},{key:"others-activator",fn:function(ref){
var data = ref.data;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"size":"38","color":"white text-caption primary--text"}},'v-avatar',attrs,false),on),[_vm._v(" +"+_vm._s(data.limit)+" ")])]}}],null,true)},[_c('span',[_vm._v("Lihat Pengguna Lain")])])]}}],null,false,183100993)}),(_vm.selection.id === 5)?_c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"8px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","x-small":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,1869696517)},[_c(VCard,{staticClass:"pa-2",attrs:{"width":"240px"}},[_c('user-auto-suggest',{attrs:{"label":"Pilih pengguna","multiple":""},model:{value:(_vm.selectUsers),callback:function ($$v) {_vm.selectUsers=$$v},expression:"selectUsers"}})],1)],1):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }