<template>
  <div>
    <div v-if="loadingProspectStatus">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <v-chip-group
      v-else
      v-model="inputData"
      active-class="primary--text"
      :multiple="multiple"
    >
      <v-chip
        v-for="status in prospectStatusData.filter(data =>!disabledChipId.includes(data.id))"
        :key="status.id"
        small
        :value="status.id"
        class="v-chip-light-bg"
      >
        {{ status.name }}
      </v-chip>
    </v-chip-group>
  </div>
</template>
<script>
import {
  computed, defineComponent, onMounted, ref,
} from '@vue/composition-api'
import useProspect from '@/composables/useProspect'

export default defineComponent({
  props: {
    value: {
      type: [Array, Object, Number],
      default: () => [],
    },
    disabledChipId: {
      type: [Array],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const prospectStatus = ref()
    const { prospectStatusData, fetchProspectStatus, loadingProspectStatus } = useProspect()
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    onMounted(() => {
      fetchProspectStatus()
    })

    return {
      inputData,
      prospectStatus,
      prospectStatusData,
      loadingProspectStatus,
    }
  },
})
</script>
