<template>
  <v-menu
    v-if="!expand"
    v-model="showCommentBox"
    :close-on-content-click="false"
    :close-on-click="false"
    top
    right
    offset-y
    nudge-top="20"
    nudge-left="20px"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="comment-button"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ icons.mdiCommentMultiple }}
        </v-icon>
      </v-btn>
    </template>
    <comment-list
      style="width: 640px"
      :job-id="jobId"
      :task-id="taskId"
      :expand="expand"
      :is-subscribed="isSubscribed"
      @close="close"
      @changeSubscription="toggleNotification"
    >
      <template #header>
        <slot name="head" />
      </template>
    </comment-list>
  </v-menu>
  <comment-list
    v-else
    :job-id="jobId"
    :task-id="taskId"
    :expand="expand"
    :expand-height="expandHeight"
    :outlined="outlined"
    :toggleable="toggleable"
    :is-subscribed="isSubscribed"
    @close="close"
    @changeSubscription="toggleNotification"
  >
    <template #header>
      <slot name="header" />
    </template>
  </comment-list>
</template>

<script>
import Vue from 'vue'
import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiCommentMultiple } from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { toggleNotificationReference } from '@/graphql/mutations'
import CommentList from './CommentBox/CommentList.vue'
import store from '@/store'

export default {
  components: {
    CommentList,
  },
  props: {
    jobId: {
      type: Number,
      default: null,
    },
    referenceId: {
      type: [String, Number],
      default: 0,
    },
    taskId: {
      type: Number,
      default: null,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    expandHeight: {
      type: [String, Number],
      default: '60',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    isSubscribed: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const showCommentBox = ref(false)
    const loadingDetail = ref(false)
    const open = () => {
      showCommentBox.value = true
    }

    const close = () => {
      showCommentBox.value = false
    }

    watch(showCommentBox, value => {
      if (value) return emit('open')

      return emit('close')
    })
    const toggleNotification = value => {
      apolloClient.mutate({
        mutation: toggleNotificationReference,
        variables: {
          is_subscribe: value,
          job_id: props.jobId,
          task_id: props.taskId,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(() => {
        Vue.notify({
          title: 'Berhasil!',
          text: value
            ? 'Berhasil mengaktifkan notifikasi untuk komentar ini!'
            : 'Berhasil menonaktifkan notifikasi untuk komentar ini!',
        })
      })
      emit('changeSubscription', value)
    }

    onMounted(() => {
    })

    return {
      showCommentBox,
      loadingDetail,
      open,
      close,
      toggleNotification,

      icons: {
        mdiCommentMultiple,
      },
    }
  },
}
</script>

<style>

</style>
