import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { tasks, taskDetail as taskDetailQuery } from '@/graphql/queries'
import { archiveTask, updateTaskDone } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

const useTask = ({
  pagination, filter, search,
} = {}) => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const taskList = ref([])
  const taskCount = ref(0)
  const loadingTasks = ref(false)
  const loadingFetchMore = ref(false)
  const timestamp = ref(null)
  const taskFilter = ref({
    pagination: {
      limit: 20,
      offset: 0,
    },
    filter: {
      sort: {
        label: 'Nama Task A-Z',
        field: 'subject',
        type: 'ASC',
      },
    },
    search: null,
  })
  const taskAutoSuggestPagination = ref({
    limit: 20,
    offset: 0,
  })

  const taskAutoSuggestSearch = ref(null)
  const sortOptions = [
    {
      label: 'Nama Task A-Z',
      field: 'subject',
      type: 'ASC',
    },
    {
      label: 'Nama Task Z-A',
      field: 'subject',
      type: 'DESC',
    },
    {
      label: 'Deadline Terlama',
      field: 'end_date',
      type: 'DESC',
    },
    {
      label: 'Deadline Terdekat',
      field: 'end_date',
      type: 'ASC',
    },
    {
      label: 'Dibuat Terlama',
      field: 'created_at',
      type: 'ASC',
    },
    {
      label: 'Dibuat Terbaru',
      field: 'created_at',
      type: 'DESC',
    },
    {
      label: 'Diupdate Terlama',
      field: 'updated_at',
      type: 'ASC',
    },
    {
      label: 'Diupdate Terbaru',
      field: 'updated_at',
      type: 'DESC',
    },
    {
      label: 'Rank',
      field: 'lexorank',
      type: 'ASC',
    },
  ]

  const pushTasks = list => {
    // const filteredList = taskList.value.filter(el => !list.some(c => c.id === el.id))

    taskList.value = [...taskList.value, ...list]
  }

  const fetchTasks = async ({ fetchMore = false } = {}) => {
    // fetchTasksCalendar()
    loadingTasks.value = true
    await apolloClient.query({
      query: tasks,
      variables: {
        workspace_id: workspaceId,
        pagination: pagination && !fetchMore ? pagination.value : taskFilter.value.pagination,
        filter: filter ? filter.value : {
          ...taskFilter.value.filter,
          sort: taskFilter.value.filter.sort.field,
          order: taskFilter.value.filter.sort.type,
        },
        search: search ? search.value : taskFilter.value.search,
        timestamp: timestamp.value,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingTasks.value = false

      taskCount.value = result.data.tasks.count
      if (fetchMore) {
        pushTasks(result.data.tasks.tasks)
      } else {
        taskList.value = result.data.tasks.tasks
      }
    }).catch(err => {
      loadingTasks.value = false

      errorHandling(err)
    })
  }

  const fetchTasksAutoSuggest = async ({ fetchMore = false } = {}) => {
    loadingTasks.value = true
    await apolloClient.query({
      query: tasks,
      variables: {
        workspace_id: workspaceId,
        pagination: pagination && !fetchMore ? pagination : taskAutoSuggestPagination.value,
        filter: {},
        search: search ? search.value : taskAutoSuggestSearch.value,
        timestamp: timestamp.value,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingTasks.value = false
      taskCount.value = result.data.tasks.count
      if (fetchMore) {
        pushTasks(result.data.tasks.tasks)
      } else {
        taskList.value = result.data.tasks.tasks
      }
    }).catch(err => {
      loadingTasks.value = false
      errorHandling(err)
    })
  }

  const listenScrollAutoSuggestFetchMore = useDebounceFn(async () => {
    taskAutoSuggestPagination.value.offset += 20
    loadingFetchMore.value = true
    await fetchTasksAutoSuggest({ fetchMore: true })
    loadingFetchMore.value = false
  }, 750)

  const listenScrollFetchMore = useDebounceFn(async data => {
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 400)
      && taskFilter.value.pagination.offset <= taskCount.value
    ) {
      taskFilter.value.pagination.offset += 20
      loadingFetchMore.value = true
      await fetchTasks({ fetchMore: true })
      loadingFetchMore.value = false
    }
  }, 200)

  const listenScrollGanttFetchMore = useDebounceFn(async data => {
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 300)
      && taskFilter.value.pagination.offset <= taskCount.value
    ) {
      taskFilter.value.pagination.offset += 20
      loadingFetchMore.value = true
      await fetchTasks({ fetchMore: true })
      loadingFetchMore.value = false
    }
  }, 200)

  const debouncedFetchTasks = useDebounceFn(() => {
    fetchTasks()
  }, 1000)

  const debouncedFetchTasksAutoSuggest = useDebounceFn(() => {
    fetchTasksAutoSuggest()
  }, 1000)

  const deleteTask = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus task?',
      body: 'Konfirmasi jika anda ingin menghapus task.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: archiveTask,
          variables: {
            id,
            is_archive: true,
            workspace_id: workspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil menghapus task!',
          })
          fetchTasks()
          resolve()
        }).catch(err => {
          reject()
          errorHandling(err, 'Hapus Task')
        })
      } else {
        reject()
      }
    })
  })

  const checkTask = (id, value = false) => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: !value ? 'Selesaikan task?' : 'Kembalikan ke belum selesai?',
      body: !value
        ? 'Konfirmasi jika anda ingin menyelesaikan task.'
        : 'Konfirmasi jika anda ingin mengembalikan task ini menjadi belum selesai',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: updateTaskDone,
          variables: {
            id,
            workspace_id: workspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil menyelesaikan task!',
          })
          fetchTasks()
          resolve()
        }).catch(err => {
          reject()
          errorHandling(err, 'Update Task')
        })
      } else {
        reject()
      }
    })
  })

  const taskDetail = ref({})
  const loadingTaskDetail = ref(false)

  const getTaskDetail = async id => {
    loadingTaskDetail.value = true

    await apolloClient.query({
      query: taskDetailQuery,
      variables: {
        task_id: id,
        workspace_id: workspaceId,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingTaskDetail.value = false
      taskDetail.value = result.data.taskDetail
    }).catch(err => {
      loadingTaskDetail.value = false
      errorHandling(err)
    })
  }

  return {
    taskList,
    taskCount,
    taskFilter,
    sortOptions,
    loadingTasks,
    loadingFetchMore,
    taskDetail,
    loadingTaskDetail,

    getTaskDetail,
    deleteTask,
    checkTask,
    fetchTasks,
    listenScrollFetchMore,
    debouncedFetchTasks,
    fetchTasksAutoSuggest,
    debouncedFetchTasksAutoSuggest,
    listenScrollAutoSuggestFetchMore,
    listenScrollGanttFetchMore,
    taskAutoSuggestSearch,
    timestamp,
  }
}

export default useTask
