import { ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import {
  prospectDetail, prospects, prospectsKanban, prospectStatus,
} from '@/graphql/queries'
import dateFormat from '@/utils/dateFormat'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import { archiveProspect } from '@/graphql/mutations'

const useProspect = () => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const prospectList = ref([])
  const prospectKanbanList = ref([])
  const prospectCount = ref(0)
  const prospectPagination = ref({
    limit: 10,
    offset: 0,
  })
  const prospectKanbanPagination = ref({
    limit: 10,
    offset: 0,
  })
  const prospectSortOptions = ref([])
  const prospectDetails = ref([])
  const loadingProspect = ref(false)
  const loadingProspectPagination = ref(false)
  const loadingProspectKanbanPagination = ref(false)
  const loadingProspectDetail = ref(false)
  const loadingProspectStatus = ref(false)
  const prospectFilter = ref({})
  const prospectStatusData = ref([])

  const fetchProspectStatus = (success = () => {}) => {
    loadingProspectStatus.value = true
    apolloClient.query({ query: prospectStatus }).then(({ data }) => {
      prospectStatusData.value = data.prospectStatus
      loadingProspectStatus.value = false
      success()
    })
  }

  const fetchProspect = async (fetchMore = false, isArchive = false, success = () => {}) => {
    loadingProspect.value = true
    if (prospectFilter.value.customer && prospectFilter.value.customer[0].id) { prospectFilter.value.customer = prospectFilter.value.customer.map(el => el.id) }
    await apolloClient.query({
      query: prospects,
      fetchPolicy: 'no-cache',
      variables: {
        pagination: {
          limit: prospectPagination.value.limit,
          offset: prospectPagination.value.offset,
        },
        filter: isArchive ? { ...prospectFilter.value, is_archive: isArchive } : prospectFilter.value,
        workspace_id: workspaceId,
      },
    }).then(result => {
      const formatDate = result.data.prospects.prospects.map(value => (
        { ...value, created_at: dateFormat(value.created_at), updated_at: dateFormat(value.updated_at, 6) }))
      if (fetchMore) {
        prospectList.value = [...prospectList.value, ...formatDate]
      } else {
        prospectList.value = formatDate
      }
      prospectCount.value = result.data.prospects.count
      console.groupCollapsed('fetchList')
      console.log('list pagination', prospectPagination.value.offset)
      console.log('prospectList', prospectList.value)
      console.groupEnd()
      loadingProspect.value = false
      success()
    }).catch(err => {
      loadingProspect.value = false
      errorHandling(err)
    })
  }

  const fetchProspectsKanban = async (fetchMore = false, isArchive = false, success = () => {}, currentLimit = false) => {
    loadingProspect.value = true
    if (prospectFilter.value.customer && prospectFilter.value.customer[0].id) { prospectFilter.value.customer = prospectFilter.value.customer.map(el => el.id) }
    await apolloClient.query({
      query: prospectsKanban,
      fetchPolicy: 'no-cache',
      variables: {
        pagination: {
          limit: currentLimit || prospectKanbanPagination.value.limit,
          offset: currentLimit ? 0 : prospectKanbanPagination.value.offset,
        },
        filter: isArchive ? { ...prospectFilter.value, is_archive: isArchive } : prospectFilter.value,
        workspace_id: workspaceId,
      },
    }).then(result => {
      const formatWon = result.data.prospectsKanban.won.map(data => ({
        ...data,
        created_at: dateFormat(data.created_at),
        updated_at: dateFormat(data.updated_at, 6),
      }))
      const formatHot = result.data.prospectsKanban.hot.map(data => ({
        ...data,
        created_at: dateFormat(data.created_at),
        updated_at: dateFormat(data.updated_at, 6),
      }))
      const formatCold = result.data.prospectsKanban.cold.map(data => ({
        ...data,
        created_at: dateFormat(data.created_at),
        updated_at: dateFormat(data.updated_at, 6),
      }))
      const formatLost = result.data.prospectsKanban.lost.map(data => ({
        ...data,
        created_at: dateFormat(data.created_at),
        updated_at: dateFormat(data.updated_at, 6),
      }))
      if (fetchMore) {
        prospectKanbanList.value = [
          ...prospectKanbanList.value,
          ...formatWon,
          ...formatHot,
          ...formatCold,
          ...formatLost,
        ]
      } else {
        prospectKanbanList.value = [
          ...formatWon,
          ...formatHot,
          ...formatCold,
          ...formatLost,
        ]
      }
      console.groupCollapsed('fetchKanban')
      console.log('kanban pagination', prospectKanbanPagination.value.offset)
      console.log('prospectKanbanList', prospectKanbanList.value)
      console.groupEnd()
      loadingProspect.value = false
      success()
    }).catch(err => {
      loadingProspect.value = false
      errorHandling(err)
    })
  }

  const fetchDetailProspect = (id, success = () => {}) => {
    loadingProspectDetail.value = true
    apolloClient.query({
      query: prospectDetail,
      fetchPolicy: 'no-cache',
      variables: {
        prospect_id: +id,
        workspace_id: store.getters.getCurrentWorkspaceId,
      },
    }).then(result => {
      prospectDetails.value = result.data.prospectDetail
      loadingProspectDetail.value = false
      success()
    }).catch(err => {
      loadingProspectDetail.value = false
      errorHandling(err)
    })
  }

  const listenScrollFetchMoreProspect = useDebounceFn(async data => {
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 100)
      && prospectKanbanPagination.value.offset <= prospectCount.value
    ) {
      console.log('masuk')
      prospectKanbanPagination.value.offset += 10
      loadingProspectKanbanPagination.value = true
      await fetchProspectsKanban(true, false, () => {
        loadingProspectKanbanPagination.value = false
      })
    }
  }, 1000)

  const listenScrollFetchMoreArchivedProspect = useDebounceFn(async data => {
    console.log(data)
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 100)
      && prospectKanbanPagination.value.offset <= prospectCount.value
    ) {
      prospectKanbanPagination.value.offset += 10
      loadingProspectKanbanPagination.value = true
      await fetchProspect(true, true, () => {
        loadingProspectKanbanPagination.value = false
      })
    }
  }, 1000)

  const debouncedFetchProspects = useDebounceFn(() => {
    fetchProspect(false, false)
    fetchProspectsKanban(false, false)
  }, 1000)
  const debouncedFetchArchivedProspects = useDebounceFn(() => {
    fetchProspect(false, true)
  }, 1000)

  const archiveProspectFunc = (id, archive, success = () => {}) => {
    Vue.$dialog({
      title: `${archive ? 'Arsipkan' : 'Pulihkan'} prospek?`,
      body: `Konfirmasi jika anda ingin ${archive ? 'mengarsipkan' : 'memulihkan'} prospek`,
    }).then(isConfirm => {
      if (isConfirm) {
        apolloClient.mutate({
          mutation: archiveProspect,
          variables: {
            id,
            is_archive: archive,
            workspace_id: workspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: `Berhasil ${archive ? 'mengarsipkan' : 'memulihkan'} prospek`,
          })
          success()
        }).catch(err => {
          errorHandling(err)
        })
      }
    })
  }

  return {
    prospectStatusData,
    fetchProspectStatus,
    prospectList,
    prospectKanbanList,
    prospectCount,
    prospectSortOptions,
    prospectDetail,
    prospectPagination,
    prospectKanbanPagination,
    prospectFilter,
    loadingProspect,
    loadingProspectPagination,
    loadingProspectKanbanPagination,
    loadingProspectDetail,
    loadingProspectStatus,
    fetchProspect,
    fetchProspectsKanban,
    fetchDetailProspect,
    archiveProspectFunc,
    listenScrollFetchMoreProspect,
    debouncedFetchProspects,
    listenScrollFetchMoreArchivedProspect,
    debouncedFetchArchivedProspects,
  }
}

export default useProspect
