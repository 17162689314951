<template>
  <div
    class="d-flex flex-column"
  >
    <div
      class="d-flex cursor-pointer align-center pa-3"
      @click="$emit('close')"
    >
      <v-icon
        size="16"
        class="mr-2 primary--text"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      <span class="primary--text text-caption">
        Kembali
      </span>
    </div>
    <div
      v-if="commentsFile.length"
      class="d-flex flex-wrap px-3"
    >
      <CommentFile
        v-for="(data, index) in commentsFile"
        :key="index"
        class="mb-2 mr-2"
        :data="data"
        @preview="$refs.docViewer.show(data.fileType, data.file_path)"
      />
    </div>
    <div
      v-else
      class="ma-auto mt-6"
    >
      <span class="text-subtitle-1 text--disabled">
        Belum ada dokumen
      </span>
    </div>

    <document-viewer ref="docViewer" />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { mdiArrowLeft } from '@mdi/js'
import DocumentViewer from '@/components/misc/DocumentViewer.vue'
import CommentFile from './CommentFile.vue'

export default {
  components: {
    CommentFile,
    DocumentViewer,
  },
  props: {
    loadingFiles: {
      type: Boolean,
    },
    commentsFile: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      emit('fetch-comment-files')
    })

    return {
      icons: {
        mdiArrowLeft,
      },
    }
  },
}
</script>

<style>

</style>
