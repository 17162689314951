import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import { tags, queryDeleteTag } from '@/graphql/queries'
import { deleteTag as mutationDeleteTag, addTag as mutationAddTag, updateTag as mutationUpdateTag } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

const useTag = () => {
  const loadingCheckDeleteTag = ref(false)
  const loadingDeleteTag = ref(false)
  const loadingAddTag = ref(false)
  const loadingUpdateTag = ref(false)
  const workspaceId = store.getters.getCurrentWorkspaceId
  const tagList = ref([])
  const loadingTags = ref(false)
  const tagFilter = ref({
    search: '',
    pagination: {
      limit: 100,
      offset: 0,
    },
  })

  const checkDeleteTagData = ref({
    job_count: 0,
    task_count: 0,
  })

  const fetchTags = async search => {
    loadingTags.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: tags,
        variables: {
          workspace_id: workspaceId,
          search,
          pagination: {
            limit: 100,
            offset: 0,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingTags.value = false
        tagList.value = data.tags
        resolve(data.tags)
      }).catch(err => {
        loadingTags.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const addTag = async ({
    name,
    color,
  }) => {
    loadingAddTag.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: mutationAddTag,
        variables: {
          name,
          color,
          workspace_id: workspaceId,
        },
      }).then(({ data }) => {
        loadingAddTag.value = false
        resolve(data.addTag)
      }).catch(err => {
        errorHandling(err)
        reject(err)
        loadingAddTag.value = false
      })
    })
  }

  const updateTag = async ({
    id,
    name,
    color,
  }) => {
    loadingUpdateTag.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: mutationUpdateTag,
        variables: {
          id,
          name,
          color,
          workspace_id: workspaceId,
        },
      }).then(({ data }) => {
        loadingUpdateTag.value = false
        resolve(data.updateTag)
      }).catch(err => {
        errorHandling(err)
        reject(err)
        loadingUpdateTag.value = false
      })
    })
  }

  const checkDeleteTag = async tagId => {
    loadingCheckDeleteTag.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: queryDeleteTag,
        variables: {
          workspace_id: workspaceId,
          tag_id: tagId,
        },
      }).then(({ data }) => {
        loadingCheckDeleteTag.value = false
        resolve(data.queryDeleteTag)
        checkDeleteTagData.value = data.queryDeleteTag
      }).catch(err => {
        errorHandling(err)
        reject(err)
        loadingCheckDeleteTag.value = false
      })
    })
  }

  const deleteTag = async tagId => {
    loadingDeleteTag.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: mutationDeleteTag,
        variables: {
          workspace_id: workspaceId,
          tag_id: tagId,
        },
      }).then(({ data }) => {
        loadingDeleteTag.value = false
        resolve(data.deleteTag)
      }).catch(err => {
        errorHandling(err)
        reject(err)
        loadingDeleteTag.value = false
      })
    })
  }

  return {
    loadingCheckDeleteTag,
    loadingDeleteTag,
    loadingTags,
    loadingAddTag,
    loadingUpdateTag,

    tagList,
    tagFilter,
    checkDeleteTagData,

    fetchTags,
    checkDeleteTag,
    deleteTag,
    addTag,
    updateTag,
  }
}

export default useTag
