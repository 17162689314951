import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{ref:"dropArea",staticClass:"drop-file-input",attrs:{"outlined":"","ripple":false},on:{"click":function($event){_vm.files.length < +_vm.maxFile ? _vm.$refs.dropFileInput.click() : null},"dragover":_vm.onDragOver,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[_c('perfect-scrollbar',{staticClass:"h-full",attrs:{"options":{
      wheelPropagation: true,
      useBothWheelAxes: true,
      suppressScrollY: true,
    }}},[_c('div',{staticClass:"d-flex align-center h-full"},[_c('draggable',{staticClass:"d-flex",attrs:{"list":_vm.files,"animation":200,"ghost-class":"ghost-card","group":"value","sort":_vm.sortable},on:{"change":_vm.updateSort}},_vm._l((_vm.files),function(file,index){return _c(VHover,{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c(VCard,{staticClass:"file",attrs:{"outlined":""}},[(file.isDummy || file.file.type.includes('image'))?_c(VImg,{staticClass:"rounded",attrs:{"height":"152","width":"180","src":file.preview ? file.preview : 'http://placekitten.com/140/110'}}):_c('div',{staticClass:"d-flex flex-column h-full w-full justify-center align-center"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiFile)+" ")]),_c('span',{staticClass:"text-caption text--disabled text-truncate mt-1"},[_vm._v(" "+_vm._s(_vm.ellipsis(file.file.name, 16))+" ")])],1),_c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(hover)?_c('div',{staticClass:"file-action d-flex flex-wrap w-full justify-center"},[(!_vm.disablePreview)?_c(VBtn,{staticClass:"mr-1",staticStyle:{"background-color":"#fff"},attrs:{"icon":"","elevation":"2","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.docViewer.show(file.isDummy ? file.type : file.file.type, file.preview)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1):_vm._e(),(_vm.files.length > 1 && _vm.sortable)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"background-color":"#fff"},attrs:{"icon":"","elevation":"2","color":"secondary"},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiArrowLeftRight)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Geser Urutan")])]):_vm._e(),_c(VBtn,{staticClass:"ml-1",staticStyle:{"background-color":"#fff"},attrs:{"icon":"","elevation":"2","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(file.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1):_vm._e()])],1)]}}],null,true)})}),1),(_vm.files.length && _vm.files.length < +_vm.maxFile)?_c(VCard,{staticClass:"file d-flex align-center justify-center flex-shrink-0",attrs:{"outlined":"","height":"152","width":"180"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.dropFileInput.click()}}},[_c(VIcon,{staticClass:"text--disabled",attrs:{"size":"28"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):(_vm.files.length === 0)?_c('div',{staticClass:"d-flex flex-column align-center mx-auto"},[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('span',{staticClass:"mt-1 text--disabled"},[_vm._v(_vm._s(_vm.subtitle))])]):_vm._e()],1)]),_c('input',{ref:"dropFileInput",staticClass:"d-none",attrs:{"type":"file","multiple":"","accept":_vm.accept},on:{"change":_vm.onFileChange}}),_c('document-viewer',{ref:"docViewer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }