/**
 * @description Masukkan harga dalam bentuk int atau number, akan di return sebuah string berisi harga yang sudah di format
 * @param {number} price Harga yang akan diolah
 * @param {number} decimal banyak desimal setelah bilangan bulat
 * @returns {string}
 */

export default function priceFormat(price) {
  const split = price.toString().length
  let formattedPrice = ''
  if (split < 7) {
    let formatPrice = (price / 1000).toFixed(1)
    formatPrice = formatPrice.toString().split('.')
    formattedPrice = `Rp${formatPrice[0]}${+formatPrice[1] !== 0 || NaN ? `,${formatPrice[1]}` : ''} Ribu`
  } else if (split > 6 && split < 10) {
    let formatPrice = (price / 1000000).toFixed(1)
    formatPrice = formatPrice.toString().split('.')
    formattedPrice = `Rp${formatPrice[0]}${+formatPrice[1] !== 0 || NaN ? `,${formatPrice[1]}` : ''} Juta`
  } else if (split > 9 && split < 13) {
    let formatPrice = (price / 1000000000).toFixed(1)
    formatPrice = formatPrice.toString().split('.')
    formattedPrice = `Rp${formatPrice[0]}${+formatPrice[1] !== 0 || NaN ? `,${formatPrice[1]}` : ''} Milyar`
  } else {
    let formatPrice = (price / 1000000000000).toFixed(1)
    formatPrice = formatPrice.toString().split('.')
    formattedPrice = `Rp${formatPrice[0]}${+formatPrice[1] !== 0 || NaN ? `,${formatPrice[1]}` : ''} Triliun`
  }

  return formattedPrice
}
