<template>
  <div
    v-if="selection"
    class="d-flex"
  >
    <div class="mr-4">
      <span class="text-caption">Visibilitas</span>
      <v-menu
        offset-y
        nudge-bottom="8px"
        content-class="vispicker"
      >
        <template v-slot:activator="menu">
          <v-tooltip
            bottom
          >
            <template v-slot:activator="tooltip">
              <v-sheet
                v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                width="60"
                height="40"
                class="mt-2 d-flex align-center justify-center"
                outlined
                rounded
                v-on="{ ...menu.on, ...tooltip.on }"
              >
                <v-icon size="20">
                  {{ selection.icon }}
                </v-icon>
              </v-sheet>
            </template>
            <span>{{ selection.name }}</span>
          </v-tooltip>
        </template>
        <v-card max-width="300px">
          <v-list
            nav
          >
            <v-list-item-group
              v-model="selection"
            >
              <v-list-item
                v-for="item in visibilityOptions"
                :key="item.id"
                :value="item"
                class="d-flex align-start py-1 mb-1"
              >
                <v-icon
                  size="20"
                  class="mt-1"
                >
                  {{ item.icon }}
                </v-icon>
                <div class="ml-2 d-flex flex-column mr-2">
                  <span class="text-subtitle-2 primary--text">{{ item.name }}</span>
                  <span class="mt-1 text-caption">{{ item.description }}</span>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div>
      <span class="text-caption">Yang Dapat Melihat</span>
      <div class="d-flex mt-2 align-center">
        <limit-elements
          :elements="selection.id === 5 ? selectUsers : visibilities.includes(selection.id) ? searchUsersTeamsResults.users : []"
          :limit="5"
          class="v-avatar-group"
          :class="rootThemeClasses"
        >
          <template #default="{data}">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="38"
                  class="mb-1"
                  v-on="on"
                >
                  <v-img
                    v-if="data.photo"
                    :src="data.photo"
                  />
                  <span v-else>{{ avatarText(data.name) }}</span>
                </v-avatar>
              </template>
              <span>{{ data.name }}</span>
            </v-tooltip>
          </template>
          <template #others="{data}">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="38"
                  class="mb-1"
                  v-on="on"
                >
                  <v-img
                    v-if="data.photo"
                    :src="data.photo"
                  />
                  <span v-else>{{ avatarText(data.name) }}</span>
                </v-avatar>
              </template>
              <span>{{ data.name }}</span>
            </v-tooltip>
          </template>
          <template #others-activator="{ data }">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="38"
                  color="white text-caption primary--text"
                  v-on="on"
                >
                  +{{ data.limit }}
                </v-avatar>
              </template>
              <span>Lihat Pengguna Lain</span>
            </v-tooltip>
          </template>
        </limit-elements>
        <v-menu
          v-if="selection.id === 5"
          offset-y
          nudge-bottom="8px"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              fab
              x-small
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                size="20"
              >
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <v-card
            width="240px"
            class="pa-2"
          >
            <user-auto-suggest
              v-model="selectUsers"
              label="Pilih pengguna"
              multiple
            />
          </v-card>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, computed, watch,
} from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import LimitElements from '@/components/misc/LimitElements.vue'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import useVisibility from '@/composables/useVisibility'
import useTeam from '@/composables/useTeam'
import { avatarText } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'

export default {
  components: {
    LimitElements,
    UserAutoSuggest,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { rootThemeClasses } = useVuetify()
    const { visibilityOptions, fetchVisibilities } = useVisibility()
    const visibilities = ref([2, 3, 4])
    const {
      loadingSearchUsersTeams, searchUsersTeamsResults, searchUsersTeams,
    } = useTeam()
    const selection = computed({
      get() {
        return visibilityOptions.value.find(el => el.id === props.value)
      },
      set(val) {
        emit('input', val.id)
      },
    })

    const selectUsers = ref([])

    onMounted(() => {
      fetchVisibilities().then(() => {
        if (!props.value) {
          selection.value = visibilityOptions.value[visibilityOptions.value.length - 1]
        }
      })
    })

    const getUsersSelection = () => selectUsers.value
    const setUsersSelection = users => {
      selectUsers.value = users
    }
    watch(selection, () => {
      if (selection.value && visibilities.value.includes(selection.value.id)) {
        searchUsersTeams('', selection.value.id, () => {
          console.log(searchUsersTeamsResults)
        })
      }
    })

    return {
      visibilityOptions,
      loadingSearchUsersTeams,
      selection,
      selectUsers,
      getUsersSelection,
      setUsersSelection,
      searchUsersTeamsResults,
      rootThemeClasses,
      avatarText,
      visibilities,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
.vispicker .v-list-item {
  height: auto !important;
}
</style>
