import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"productAutoSuggest",attrs:{"placeholder":"Cari produk...","label":"Produk","outlined":"","dense":"","loading":_vm.loading,"items":_vm.productList,"multiple":_vm.multiple,"item-text":"name","item-value":"id","chips":"","deletable-chips":"","small-chips":_vm.multiple,"hide-details":"auto","no-filter":"","rules":_vm.inputRules,"return-object":_vm.emitMode},on:{"update:search-input":_vm.onSearch,"update:list-index":_vm.onIndex},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-subtitle-2 text--disabled text-center"},[_c('p',[_vm._v(" Produk tidak ditemukan. ")]),_c('p',{staticClass:"mb-0 text-decoration-underline primary--text",on:{"click":function($event){return _vm.$refs.productForm.show(_vm.searchQuery)}}},[_vm._v(" Tambah "+_vm._s(_vm.searchQuery)+" ke data produk ")]),_c('ProductForm',{ref:"productForm"})],1)]},proxy:true},{key:"item",fn:function(data){return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[((data.item.photo.length > 0))?_c(VImg,{attrs:{"src":data.item.photo[0].photo}}):_vm._e()],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(" "+_vm._s(data.item.code_sku ? data.item.code_sku : "Tidak ada SKU")+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(data.item.price)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" Stok "+_vm._s(data.item.stock)+" ")])],1)]}}]),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }