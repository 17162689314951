<template>
  <v-autocomplete
    ref="customerAutoSuggest"
    v-model="inputData"
    placeholder="Cari pelanggan..."
    :label="label"
    outlined
    dense
    deletable-chips
    chips
    :attach="attach"
    :return-object="returnObject"
    :multiple="multiple"
    :loading="loadingCustomer"
    :items="options"
    item-value="id"
    item-text="name"
    small-chips
    hide-details="auto"
    no-filter
    :rules="inputRules"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Pelanggan tidak ditemukan.
        </p>
        <p
          class="mb-0 text-decoration-underline primary--text"
          @click="$refs.customerForm.show(searchQuery)"
        >
          Tambah {{ searchQuery }} ke data pelanggan
        </p>
        <CustomerForm ref="customerForm" />
      </div>
    </template>
    <template
      #item="data"
    >
      <div class="d-flex my-2">
        <v-avatar
          size="36"
          class="mr-2 v-avatar-light-bg"
        >
          <span>{{ avatarText(data.item.name) }}</span>
        </v-avatar>
        <div class="d-flex flex-column">
          <span class="text-subtitle-2">
            {{ data.item.name }}
          </span>
          <span class="text-caption">
            {{ data.item.email }}
          </span>
          <span class="text-caption">
            {{ data.item.phone_number }}
          </span>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { minArrayLength, maxArrayLength } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import CustomerForm from '@/views/customer/CustomerForm.vue'
import useCustomer from '@/composables/useCustomer'

export default {
  components: {
    CustomerForm,
  },
  props: {
    value: {
      type: [Array, Object, Number],
      default: null,
    },
    attach: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Pelanggan',
    },
  },
  setup(props, { emit }) {
    const customerAutoSuggest = ref(null)
    const isEmpty = ref(false)
    const searchQuery = ref('')

    const {
      loadingCustomer, customerList,
      fetchCustomers, debouncedFetchCustomers,
    } = useCustomer({
      search: searchQuery,
    })

    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const inputRules = computed(() => {
      if (props.multiple && (props.min || props.max)) {
        const multipleRules = []

        if (props.max) multipleRules.push(maxArrayLength(inputData.value, props.max))
        if (props.min) multipleRules.push(minArrayLength(inputData.value, props.min))

        return [...props.rules, ...multipleRules]
      }

      return props.rules
    })

    const options = computed(() => {
      if (inputData.value) {
        return Array.isArray(inputData.value) ? [
          ...customerList.value,
          ...inputData.value,
        ] : [
          ...customerList.value,
          inputData.value,
        ]
      }

      return customerList.value
    })

    onMounted(() => {
      fetchCustomers()
    })

    const onSearch = data => {
      searchQuery.value = data
      debouncedFetchCustomers()
    }

    const onIndex = () => {
      isEmpty.value = customerAutoSuggest.value.filteredItems.length === 0
    }

    return {
      customerAutoSuggest,
      options,
      loadingCustomer,
      customerList,
      fetchCustomers,
      inputData,
      searchQuery,
      onSearch,
      isEmpty,
      onIndex,
      inputRules,
      avatarText,
    }
  },
}
</script>

<style>
</style>
