import gql from 'graphql-tag'

const commentSubscription = gql`
  subscription(
    $job_id: Float
    $task_id: Float
  ) {
    commentSubscription(
      job_id: $job_id
      task_id: $task_id
    ) {
      id
      comment
      file {
        id
        fileType
        file_name
        file_path
      }
      user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      actionType
      created_at
    }
  }
`

export default commentSubscription
