<template>
  <v-card
    outlined
    class="file mb-1 mr-1"
    height="80"
    width="120"
    @click="$emit('preview')"
  >
    <v-img
      v-if="data.fileType.includes('image')"
      class="rounded"
      height="80"
      width="120"
      :src="data.file_path"
    />
    <div
      v-else
      class="d-flex flex-column h-full w-full justify-center align-center"
    >
      <v-icon>
        {{ icons.mdiFile }}
      </v-icon>
      <span class="text-caption text--disabled text-truncate mt-1">
        {{ ellipsis(data.file_name, 12) }}
      </span>
    </div>
  </v-card>
</template>

<script>
import { mdiFile } from '@mdi/js'
import { ellipsis } from '@core/utils/filter'

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup() {
    const getFileName = file => {
      const split = file.split('/')

      return split.pop()
    }

    return {
      getFileName,
      ellipsis,

      icons: {
        mdiFile,
      },
    }
  },
}
</script>

<style>

</style>
