<template>
  <v-card>
    <v-card-title>
      <!-- <v-row> -->
      <!-- <v-col> -->
      Produk
      <!-- </v-col> -->
      <!-- <v-spacer />
        <v-col
          cols="2"
          class="d-flex justify-end"
        >
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="20px"
                v-bind="attrs"
                class="ml-auto"
                v-on="on"
              >
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="$refs.prospectProduct.update([{id:1},{id:2},{id:3}])">
                Ubah Produk
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col> -->
      <!-- </v-row> -->
    </v-card-title>
    <v-card-text>
      <PerfectScrollbar
        v-if="detail.product.length"
        class="ps-product"
      >
        <v-card
          v-for="dataProduct in detail.product"
          :key="dataProduct.id"
          outlined
          class="mb-4"
        >
          <v-row
            class="ml-2 my-2"
            dense
          >
            <v-col
              cols="12"
              sm="6"
              class="d-flex"
            >
              <router-link
                :to="{ name: 'product-detail', params: { id: dataProduct.product.id} }"
              >
                <div
                  class="justify-center d-flex flex-column ml-4"
                >
                  <p

                    class="subtitle-1  mb-0 font-weight-bold primary--text"
                  >
                    {{ dataProduct.product.name }}
                  </p>
                  <p
                    class="caption mb-0 font-weight-semibold text--disabled"
                  >
                    {{ dataProduct.product.code_sku ? dataProduct.product.code_sku : "Tidak ada kode SKU" }}
                  </p>
                </div>
              </router-link>
            </v-col>
            <v-col
              cols="6"
              sm="3"
              class="d-flex flex-column align-start"
            >
              <div class="d-flex flex-column align-end">
                <p class="mb-0">
                  {{ priceFormat(dataProduct.product.price) }}
                </p>
                <p class="mb-0">
                  x {{ dataProduct.quantity }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="6"
              sm="3"
            >
              <p class="font-weight-bold">
                {{ priceFormat(dataProduct.product.price * dataProduct.quantity) }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </PerfectScrollbar>
      <div
        v-else
        class="pa-6 d-flex justify-center align-center"
      >
        <p class="mb-0">
          Tidak ada data yang tersedia
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import priceFormat from '@/utils/priceFormat'

export default defineComponent({
  components: {
    PerfectScrollbar,
  },
  name: 'ProductProspect',
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      priceFormat,
      icons: {
        mdiDotsVertical,
      },
    }
  },
})
</script>
<style lang="scss">
.ps-product {
  max-height: 20em;
}
.product-picture{
  border-radius: 5px;
}
</style>
