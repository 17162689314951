<template>
  <div>
    <!-- Header -->
    <app-breadcrumb
      title="Detail Prospek"
      hide-breadcrumbs
    >
      <template
        #action
      >
        <div v-if="$vuetify.breakpoint.smAndUp">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mx-1 cursor-pointer"
                color="success"
                v-on="on"
                @click="updateStatus(prospectStatusData.find(data => data.name === 'Won'))"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </template>
            <span class="subtitle-2">Win</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mx-1 cursor-pointer"
                v-bind="attrs"
                color="error"
                v-on="on"
                @click="updateStatus(prospectStatusData.find(data => data.name === 'Lost'))"
              >
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <span class="subtitle-2">Lose</span>
          </v-tooltip>
          <v-tooltip
            v-if="$can('update', 'Prospect')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mx-1 cursor-pointer"
                color="secondary"
                v-on="on"
                @click="$refs.prospectForm.update(detail.id)"
              >
                {{ icons.mdiCircleEditOutline }}
              </v-icon>
            </template>
            <span class="subtitle-2">Ubah Prospek</span>
          </v-tooltip>

          <v-divider
            vertical
            class="mx-1"
            style="display: inline;"
          />
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mx-1 cursor-pointer"
                color="secondary"
                @click="$refs.taskForm.show()"
                v-on="on"
              >
                {{ icons.mdiTextBoxPlusOutline }}
              </v-icon>
            </template>
            <span class="subtitle-2">Tambah Tugas</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mx-1 cursor-pointer"
                color="secondary"
                v-on="on"
              >
                {{ icons.mdiAccountEditOutline }}
              </v-icon>
            </template>
            <span class="subtitle-2">Ubah Pengguna yang Bertugas</span>
          </v-tooltip> -->
          <v-tooltip
            v-if="$can('del', 'Prospect')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mx-1 cursor-pointer"
                color="secondary"
                v-on="on"
                @click="archiveProspect()"
              >
                {{ icons.mdiArchiveArrowDownOutline }}
              </v-icon>
            </template>
            <span class="subtitle-2">Arsipkan Prospek</span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-icon
            color="secondary"
          >
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </div>
      </template>
    </app-breadcrumb>
    <!-- End Header -->

    <v-row>
      <!-- Detail -->
      <v-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-card class="mb-6">
          <v-card-title>
            <span class="text-h6 text--primary font-weight-bold">
              {{ detail.name }}
            </span>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="body-2 mb-0"
                  v-bind="attrs"
                  v-on="on"
                >   {{ dateFormat(detail.updated_at,8) }}</span>
              </template>
              <span class="d-flex flex-column align-center">
                <p class="body-2 mb-0">
                  Terakhir Diupdate {{ dateFormat(detail.updated_at) }}
                </p>
                <p class="body-2 mb-0">
                  Waktu dibuat {{ dateFormat(detail.created_at) }}
                </p>
              </span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="d-flex flex-column">
            <perfect-scrollbar class="ps-detail-prospect">
              <p class="mb-0 text-caption">
                {{ detail.description ? detail.description : "Tidak ada detail prospek" }}
              </p>
            </perfect-scrollbar>
            <p class="font-weight-semibold text--disabled text-caption mt-4 mb-2">
              Dibuat oleh
            </p>
            <div class="d-flex align-center mb-2">
              <v-avatar
                size="36"
                color="primary"
                class="white--text text-caption"
              >
                <span>{{ detail.created_by && avatarText(detail.created_by.name) }}</span>
              </v-avatar>
              <div class="d-flex flex-column ml-4">
                <router-link
                  :to="{ name: 'user-detail', params: { id: detail.created_by && detail.created_by.id } }"
                  class="mb-0 font-weight-semibold primary--text"
                >
                  {{ detail.created_by && detail.created_by.name }}
                </router-link>
                <span class="text-caption text--disabled font-medium"> Role - {{ detail.created_by && detail.created_by.email ? detail.created_by.email : "Tidak ada email" }}</span>
              </div>
            </div>
            <div>
              <p class="font-weight-semibold text--disabled text-caption mb-1">
                Status
              </p>
              <ChipDisplay
                v-if="detail.status"
                :status="detail.status"
              />
            </div>
            <div>
              <p class="font-weight-semibold text--disabled mt-2 text-caption mb-0">
                Visibilitas
              </p>
              <p class="text-subtitle-2 font-medium mb-0">
                {{ detail.visibility && detail.visibility.name }}
              </p>
            </div>
            <div>
              <p class="font-weight-semibold text--disabled mt-2 text-caption mb-0">
                Nilai Prospek
              </p>
              <p class="text-subtitle-2 font-medium mb-0">
                {{ detail.value ? priceFormat(detail.value) : "-" }}
              </p>
            </div>
            <div>
              <p class="font-weight-semibold text--disabled mt-2 text-caption mb-0">
                Tanggal Kadaluarsa
              </p>
              <p class="text-subtitle-2 font-medium mb-0">
                {{ detail.expired_at ? dateFormat(detail.expired_at, 1) : "-" }}
              </p>
            </div>
            <span class="text--disabled text-caption mt-2">Ditugaskan ke</span>
            <div
              class="d-flex flex-row mt-2"
            >
              <limit-elements
                v-if="detail.assignedTo && detail.assignedTo.length"
                :elements="detail.assignedTo"
                :limit="3"
                class="v-avatar-group mr-auto"
                :class="rootThemeClasses"
              >
                <template #default="el">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="36"
                        class="primary"
                        v-on="on"
                      >
                        <v-img
                          v-if="el.data.user.photo"
                          :src="el.data.user.photo"
                        />
                        <span
                          v-else
                          class="white--text"
                        >
                          {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                        </span>
                      </v-avatar>
                    </template>
                    <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
                  </v-tooltip>
                </template>
                <template #others="el">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="36"
                        class="primary mb-1"
                        v-on="on"
                      >
                        <v-img
                          v-if="el.data.user.photo"
                          :src="el.data.user.photo"
                        />
                        <span
                          v-else
                          class="white--text"
                        >
                          {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                        </span>
                      </v-avatar>
                    </template>
                    <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
                  </v-tooltip>
                </template>
                <template #others-activator="el">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="36"
                        color="white"
                        class="primary--text text-caption"
                        v-on="on"
                      >
                        <span>+{{ el.data.limit }}</span>
                      </v-avatar>
                    </template>
                    <span>Lihat User Lain</span>
                  </v-tooltip>
                </template>
              </limit-elements>
              <v-menu
                v-model="showAssignForm"
                :close-on-content-click="false"
                left
              >
                <template v-slot:activator="menu">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="tooltip">
                      <v-btn
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        text
                        outlined
                        small
                        rounded
                        color="primary"
                        class="ml-auto mt-1"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop
                      >
                        <v-icon
                          size="18"
                        >
                          {{ icons.mdiAccountPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ditugaskan ke</span>
                  </v-tooltip>
                </template>

                <AssignObject
                  ref="assignObject"
                  :prospect-id="+id"
                  :data="detail.assignedTo"
                  :visibility-id="detail.visibility && detail.visibility.id"
                  @success="assignUpdate(); showAssignForm = false"
                />
              </v-menu>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="d-flex flex-column mb-6">
          <div class="pa-3 d-flex justify-space-between flex-wrap align-center">
            <span class="text-subtitle-1 font-medium">Atribut lainnya</span>
            <v-tooltip
              top
            >
              <template #activator="{on, attrs}">
                <v-card
                  class="d-flex horizontal-list-button"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    class="align-self-center"
                    elevation="0"
                    small
                    outlined
                    color="primary"
                    @click="$refs.customAttributesList.open()"
                  >
                    <v-icon
                      size="22"
                    >
                      {{ icons.mdiCog }}
                    </v-icon>
                  </v-btn>
                </v-card>
              </template>
              <span>Edit / Tambah atribut</span>
            </v-tooltip>
          </div>
          <v-divider />
          <div class="d-flex flex-column">
            <div class="d-flex justify-space-between align-center my-2">
              <PerfectScrollbar
                :options="{
                  useBothWheelAxes:true
                }"
                class="horizontal-list px-4 pt-0 pb-4"
              >
                <prospect-info
                  v-for="attr in customAttributesArray"
                  :key="attr.id"
                  :title="attr.custom_attribute.name"
                  class="mt-2"
                >
                  {{ getAttributeValue(attr) }}
                </prospect-info>
              </perfectscrollbar>
            </div>
          </div>
        </v-card>
        <Customer
          class="mt-6"
          :detail="detail"
        />
        <Product
          v-if="detail.product"
          class="mt-6"
          :detail="detail"
        />
        <Order
          class="mt-6"
          :detail="detail"
        />
      </v-col>

      <!-- End Detail -->
      <!-- Task -->
      <v-col
        cols="12"
        md="8"
        lg="8"
      >
        <v-card>
          <div class="d-flex pa-4 justify-between">
            <span class="text-subtitle-1 font-medium">
              Task Prospek
            </span>
            <v-spacer />
            <v-btn
              small
              @click="$refs.taskFilter.show()"
            >
              <v-icon>
                {{ icons.mdiFilterOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              small
              @click="$refs.taskForm.show()"
            >
              Buat Task
            </v-btn>
          </div>
          <v-divider />
          <div
            id="taskList"
            v-scroll:#taskList="listenScrollFetchMore"
            class="task-list"
          >
            <TaskCard
              v-for="(task, index) in taskList"
              :key="`${task.id}${index}`"
              :is-ticket-prospect-task="true"
              :task="task"
              @check="checkTask(task.id, task.completed_at != null)"
              @delete="deleteTask"
              @detail="$refs.taskDetail.show(task.id)"
            />
            <div
              class="d-flex my-2"
              style="min-height: 48px"
            >
              <v-progress-circular
                v-if="loadingFetchMore"
                indeterminate
                color="primary"
                class="mx-auto"
              />
            </div>
          </div>
          <TaskFilter
            ref="taskFilter"
            @filter="onTaskFilter"
          />
        </v-card>
        <Timeline
          ref="timeline"
          class="mt-6"
          name="Prospect"
          type="prospect"
          :object-id="props.id"
        />
      </v-col>
      <!-- End Task -->
    </v-row>
    <CommentBox
      v-if="$can('read', 'Comment')"
      :reference-id="props.id"
      :reference-type="3"
      :is-subscribed="detail.is_subscribed"
    />
    <ProspectForm
      ref="prospectForm"
      @success="fetchDetail"
    />
    <TaskForm
      ref="taskForm"
      :id_prospek="props.id"
    />
    <TaskDetail ref="taskDetail" />
    <CustomAttributesList
      ref="customAttributesList"
      :object-id="+id"
      :object-type-id="3"
      @update="fetchCustomAttributes(+id)"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  mdiInformation, mdiCheck, mdiClose, mdiCircleEditOutline, mdiAccountEditOutline, mdiTextBoxPlusOutline, mdiArchiveArrowDownOutline, mdiDotsVertical,
  mdiCurrencyUsd, mdiFormatListText, mdiFilterOutline, mdiFormatListBulleted, mdiCalendarMonthOutline, mdiAccountPlus, mdiCog,
} from '@mdi/js'
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { getVuetify } from '@core/utils'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { prospectDetail } from '@/graphql/queries'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import Timeline from '@/components/misc/Timeline.vue'
import Customer from '@/views/prospect/component/Customer.vue'
import Product from '@/views/prospect/component/Product.vue'
import avatars from '@/assets/images/avatars/9.png'
import CommentBox from '@/components/misc/CommentBox.vue'
import ProspectForm from '@/views/prospect/component/ProspectForm.vue'
import TaskCard from '@/views/activity/task/TaskCard.vue'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import dateFormat from '@/utils/dateFormat'
import errorHandling from '@/utils/errorHandling'
import TaskFilter from '@/views/activity/task/TaskFilter.vue'
import store from '@/store'
import LimitElements from '@/components/misc/LimitElements.vue'
import AssignObject from '@/components/inputs/AssignObject.vue'
import Order from '@/views/prospect/component/Order.vue'
import ChipDisplay from '@/views/prospect/component/ChipDisplay.vue'
import priceFormat from '@/utils/priceFormat'
import useTask from '@/composables/useTask'
import TaskDetail from '@/views/activity/task/TaskDetail.vue'
import router from '@/router'
import { deleteProspect, updateProspectStatus } from '@/graphql/mutations'
import useProspect from '@/composables/useProspect'
import ProspectInfo from './component/ProspectDetailInfo.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import CustomAttributesList from '../custom-attributes/CustomAttributesList.vue'

export default {
  components: {
    AppBreadcrumb,
    Timeline,
    Customer,
    Product,
    CommentBox,
    PerfectScrollbar,
    ProspectForm,
    TaskCard,
    TaskForm,
    TaskFilter,
    LimitElements,
    AssignObject,
    Order,
    ChipDisplay,
    TaskDetail,
    ProspectInfo,
    CustomAttributesList,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const viewTab = ref(0)
    const { rootThemeClasses } = getVuetify()
    const loadingDetail = ref(false)
    const detail = ref({})
    const taskLists = ref({})
    const taskActiveFilter = ref({})
    const taskFilter = ref()
    const showAssignForm = ref(false)
    const timeline = ref()
    const {
      loadingTasks, taskList, fetchTasks, checkTask, loadingFetchMore,
      listenScrollFetchMore, deleteTask,
    } = useTask({
      filter: {
        prospect_id: +props.id,
        sort: 'end_date',
        order: 'DESC',
      },
    })

    const {
      fetchCustomAttributes, loadingFetch, customAttributesArray, getAttributeValue,
    } = useCustomAttributes({ objectTypeId: 3 })

    const { prospectStatusData, fetchProspectStatus } = useProspect()
    const fetchDetail = () => {
      loadingDetail.value = true
      apolloClient.query({
        query: prospectDetail,
        fetchPolicy: 'no-cache',
        variables: {
          prospect_id: +props.id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        console.log(result)
        detail.value = result.data.prospectDetail
        loadingDetail.value = false
        fetchTasks()
      }).catch(err => {
        loadingDetail.value = false
        router.push('/error')
        errorHandling(err)
      })
    }
    const onTaskFilter = data => {
      taskActiveFilter.value = data
      fetchTasks()
    }
    const assignUpdate = () => {
      fetchDetail()
      timeline.value.fetch()
    }
    const archiveProspect = () => {
      Vue.$dialog({
        title: 'Hapus Prospek?',
        body: 'Yakin ingin hapus Prospek?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: deleteProspect,
            variables: {
              id: +props.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus prospek!',
            })
            router.push(`/${store.getters.getCurrentWorkspace.identifier_id}/prospects`)
          })
        }
      })
    }
    // eslint-disable-next-line camelcase
    const updateStatus = status => {
      Vue.$dialog({
        title: 'Update Status?',
        body: `Yakin ingin update status ke ${status.name}?`,
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: updateProspectStatus,
            variables: {
              id: +props.id,
              status_id: status.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah status prospek!',
            })
            fetchDetail()
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }
    onMounted(() => {
      fetchProspectStatus()
      fetchDetail()
      fetchCustomAttributes()
    })

    return {
      updateStatus,
      prospectStatusData,
      viewTab,
      loadingDetail,
      detail,
      formatDateToMonthShort,
      avatars,
      avatarText,
      props,
      fetchDetail,
      rootThemeClasses,
      dateFormat,
      taskLists,
      loadingTasks,
      taskFilter,
      onTaskFilter,
      timeline,
      assignUpdate,
      showAssignForm,
      priceFormat,
      taskList,
      checkTask,
      loadingFetchMore,
      listenScrollFetchMore,
      deleteTask,
      archiveProspect,
      icons: {
        mdiAccountPlus,
        mdiDotsVertical,
        mdiInformation,
        mdiCheck,
        mdiClose,
        mdiCircleEditOutline,
        mdiAccountEditOutline,
        mdiTextBoxPlusOutline,
        mdiArchiveArrowDownOutline,
        mdiCurrencyUsd,
        mdiFormatListText,
        mdiFilterOutline,
        mdiFormatListBulleted,
        mdiCalendarMonthOutline,
        mdiCog,
      },
      customAttributesArray,
      fetchCustomAttributes,
      loadingFetch,
      getAttributeValue,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.ps-detail-prospect{
  max-height: 6em;
}
.task-list {
  @include style-scroll-bar();
  max-height: calc(60vh + 153px);
  overflow-y: scroll;
}
</style>
