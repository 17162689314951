<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    scrollable
    @input="!$event ? reset() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Produk' : 'Tambah Produk' }}</span>
        <v-icon
          @click="isOpen = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="productForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.name"
                :rules="[required]"
                label="Nama (wajib diisi)"
                placeholder="Input nama"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.status"
                label="Status Produk"
                placeholder="Input status produk"
                outlined
                dense
                :rules="[required]"
                type="number"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.price"
                :rules="[required]"
                label="Harga Produk"
                placeholder="Input harga produk"
                outlined
                dense
                type="number"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.stock"
                :rules="[required]"
                label="Stok Produk"
                placeholder="Input stok produk"
                outlined
                dense
                type="number"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="formData.description"
                :rules="[required]"
                label="Deskripsi Produk"
                placeholder="Input deskripsi produk"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <drop-file
            v-if="isOpen"
            ref="productPhoto"
            max-file="5"
            max-size="5000"
            class="my-4"
            label="Taruh foto produk disini. (.jpg, .jpeg, .png)"
            accept="image/*"
            subtitle="Maksimal 5MB"
          />
          <div class="d-flex flex-column flex-md-row mt-4">
            <v-btn
              class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
              outlined
              @click="reset()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingSubmit"
              @click="submitForm()"
            >
              Simpan Produk
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import DropFile from '@/components/inputs/DropFile.vue'
import { apolloClient } from '@/vue-apollo'
import { addProduct, updateProductPhoto, updateProduct } from '@/graphql/mutations'
import router from '@/router'
import { productDetail } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  components: {
    DropFile,
  },
  setup(props, { emit }) {
    const productForm = ref()
    const isOpen = ref(false)
    const formData = ref({
      id: null,
      name: '',
      description: '',
      status: null,
      price: null,
      stock: null,
    })
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const updateMode = ref(false)
    const productPhoto = ref()

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false

      // reset
      formData.value = {
        id: null,
        name: '',
        description: '',
        status: null,
        price: null,
        stock: null,
      }

      productForm.value.reset()
    }
    const show = name => {
      if (name) {
        formData.value.name = name
      }
      isOpen.value = true
    }
    const close = () => {
      isOpen.value = false
      reset()
    }

    const update = id => {
      isOpen.value = true
      loadingDetail.value = true
      apolloClient.query({
        query: productDetail,
        fetchPolicy: 'no-cache',
        variables: {
          product_id: +id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        const data = result.data.productDetail
        formData.value = {
          id,
          name: data.name,
          description: data.description,
          status: data.status,
          price: data.price,
          stock: data.stock,
        }
        if (data.photo) {
          data.photo.forEach(file => {
            productPhoto.value.addFile(file.id, file.photo, file.order_photo, true, 'image')
          })
        }

        updateMode.value = true
        loadingDetail.value = false
      }).catch(err => {
        close()
        errorHandling(err)
      })
    }

    const submitForm = async () => {
      if (productForm.value.validate()) {
        loadingSubmit.value = true
        if (!updateMode.value) {
          const photos = productPhoto.value.getAcceptedFiles()
          apolloClient.mutate({
            mutation: addProduct,
            variables: {
              ...formData.value,
              price: +formData.value.price,
              status: +formData.value.status,
              stock: +formData.value.stock,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(async result => {
            const { id } = JSON.parse(result.data.addProduct.data)
            if (photos.length) {
              await apolloClient.mutate({
                mutation: updateProductPhoto,
                variables: {
                  workspace_id: store.getters.getCurrentWorkspaceId,
                  productId: id,
                  photoOrder: photos.map(el => ({
                    order: el.order,
                    productPhotoId: null,
                    photo: el.file,
                  })),
                },
              })
            }
            emit('success')
            loadingSubmit.value = false
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menambahkan produk!',
            })
            close()
            router.push({ name: 'product-detail', params: { id } })
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        } else {
          const photos = productPhoto.value.getAcceptedFiles()
          apolloClient.mutate({
            mutation: updateProduct,
            variables: {
              ...formData.value,
              price: +formData.value.price,
              status: +formData.value.status,
              stock: +formData.value.stock,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(async () => {
            await apolloClient.mutate({
              mutation: updateProductPhoto,
              variables: {
                workspace_id: store.getters.getCurrentWorkspaceId,
                productId: formData.value.id,
                photoOrder: photos.length ? photos.map(el => ({
                  order: el.order,
                  productPhotoId: el.isDummy ? el.id : null,
                  photo: el.isDummy ? null : el.file,
                })) : null,
              },
            })
            emit('success')
            loadingSubmit.value = false
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah produk!',
            })
            close()
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        }
      }
    }

    return {
      productForm,
      isOpen,
      formData,
      loadingSubmit,
      loadingDetail,
      updateMode,
      productPhoto,

      show,
      close,
      reset,
      update,
      submitForm,

      icons: {
        mdiClose,
      },

      required,
    }
  },
}
</script>

<style>

</style>
