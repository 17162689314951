<template>
  <div
    style="border: 1px solid transparent"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <v-slide-y-reverse-transition>
      <v-card
        v-if="showMentionBox"
        outlined
        class="mention-box"
        elevation="4"
        :loading="loadingSearch"
        loader-height="2px"
      >
        <v-list
          v-if="mentionResult.users.length || mentionResult.teams.length || mentionResult.prospects.length || mentionResult.jobs.length"
          dense
        >
          <span
            v-if="mentionResult.users.length"
            class="text-caption text-primary d-block ml-3 mb-2"
          >Pengguna</span>
          <v-list-item
            v-for="(user, i) in mentionResult.users"
            :key="i"
            class="d-flex align-center mb-2"
            @keydown="selectMention(user, 'user')"
            @click="selectMention(user, 'user')"
          >
            <v-avatar
              size="28"
              class="mr-2 mt-1"
            >
              <v-img src="http://placekitten.com/140/110" />
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-caption font-medium primary--text">{{ user.name }}</span>
              <span
                class="text-caption text--disabled"
                style="line-height: 1rem"
              >
                {{ user.email }}
              </span>
            </div>
          </v-list-item>
          <span
            v-if="mentionResult.teams.length"
            class="text-caption text-primary d-block ml-3 mt-4 mb-2"
          >Tim</span>
          <v-list-item
            v-for="(team, j) in mentionResult.teams"
            :key="j"
            class="d-flex align-center mb-2"
            @keydown="selectMention(team, 'team')"
            @click="selectMention(team, 'team')"
          >
            <v-avatar
              size="28"
              class="mr-2 mt-1"
            >
              <v-img src="http://placekitten.com/140/110" />
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-caption font-medium primary--text">{{ team.name }}</span>
              <span
                class="text-caption text--disabled"
                style="line-height: 1rem"
              >
                {{ team.description }}
              </span>
            </div>
          </v-list-item>
          <span
            v-if="mentionResult.prospects.length"
            class="text-caption text-primary d-block ml-3 mt-4 mb-2"
          >Prospek</span>
          <v-list-item
            v-for="(prospect, j) in mentionResult.prospects"
            :key="j"
            class="d-flex align-center mb-2"
            @keydown="selectMention(prospect, 'prospect')"
            @click="selectMention(prospect, 'prospect')"
          >
            <div class="d-flex flex-column">
              <span class="text-caption font-medium primary--text">{{ prospect.name }}</span>
              <span
                class="text-caption text--disabled"
                style="line-height: 1rem"
              >
                {{ prospect.description }}
              </span>
            </div>
          </v-list-item>
          <span
            v-if="mentionResult.jobs.length"
            class="text-caption text-primary d-block ml-3 mt-4 mb-2"
          >Job</span>
          <v-list-item
            v-for="(job, j) in mentionResult.jobs"
            :key="j"
            class="d-flex align-center mb-2"
            @keydown="selectMention(job, 'job')"
            @click="selectMention(job, 'job')"
          >
            <div class="d-flex flex-column">
              <span class="text-caption font-medium primary--text">{{ job.name }}</span>
              <span
                class="text-caption text--disabled"
                style="line-height: 1rem"
              >
                {{ job.description }}
              </span>
            </div>
          </v-list-item>
        </v-list>
        <div
          v-else
          class="pa-2 d-flex justify-space-between"
        >
          <span class="text-subtitle-2 text--disabled">
            {{ searchKeyword ? 'Tidak ditemukan' : 'Mention pengguna, job, atau prospek' }}
          </span>
          <v-icon
            color="primary"
            size="20px"
            @click="showMentionBox = false; searchKeyword = ''"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
      </v-card>
    </v-slide-y-reverse-transition>
    <div
      class="bottom pa-2"
    >
      <div
        v-if="isOpen"
        id="commentBox"
        ref="commentBox"
        contenteditable="true"
        class="pa-1 input"
        :placeholder="updateMode ? 'Ubah komentar...' : 'Buat komentar...'"
        @input="onInput"
        @keydown="onKeydown"
        @click="updateCaretPosition()"
      />
      <limit-elements
        v-if="isOpen"
        :elements="documents"
        :limit="2"
        class="mb-2"
      >
        <template #default="{ data }">
          <v-chip
            class="ma-1"
            close
            color="primary"
            label
            outlined
            small
            @click="$refs.docViewer.show(data.file.type, data.preview)"
            @click:close="deleteDoc(data.index)"
          >
            {{ ellipsis(data.file.name, 24) }}
          </v-chip>
        </template>
        <template #others="{ data }">
          <v-chip
            class="ma-1"
            close
            color="primary"
            label
            outlined
            small
            @click="$refs.docViewer.show(data.file.type, data.preview)"
            @click:close="deleteDoc(data.index)"
          >
            {{ ellipsis(data.file.name, 24) }}
          </v-chip>
        </template>
        <template #others-activator="{ data }">
          <span class="text--disabled">+ {{ data.limit }} lainnya</span>
        </template>
      </limit-elements>
      <v-divider
        v-if="isOpen"
        class="mb-2"
      />
      <div
        v-if="isOpen"
        class="d-flex align-center"
      >
        <v-btn
          v-if="toggleable"
          color="primary"
          small
          text
          icon
          @click="isOpen = false; $emit('close')"
        >
          <v-icon
            size="20"
          >
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!updateMode"
          color="primary"
          small
          text
          icon
          @click="$refs.dropFileInput.click()"
        >
          <v-icon
            size="20"
          >
            {{ icons.mdiAttachment }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          class="ml-auto mr-4"
          :class="{ 'pulse-red': isListening }"
        >
          <v-icon
            v-if="isSupported"
            size="20"
            :color="isListening ? 'error' : null"
            @click="isListening ? stop() : start()"
          >
            {{ icons.mdiMicrophone }}
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          small
          :loading="loadingSubmit"
          @click="submitComment"
        >
          {{ updateMode ? 'Update' : 'Kirim' }}
        </v-btn>
      </div>
      <div
        v-else
        class="d-flex align-center justify-center"
      >
        <v-btn
          color="primary"
          small
          text
          class="px-1"
          @click="isOpen = true; $emit('open')"
        >
          Buat Komentar
          <v-icon
            size="18"
            class="ml-2"
          >
            {{ icons.mdiCommentPlus }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <input
      ref="dropFileInput"
      type="file"
      class="d-none"
      multiple
      @change="onFileChange"
    >

    <document-viewer ref="docViewer" />
  </div>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import {
  mdiAttachment, mdiPlus, mdiMicrophone, mdiCommentPlus, mdiChevronDown, mdiClose,
} from '@mdi/js'
import { useSpeechRecognition, useDebounceFn } from '@vueuse/core'
import { ellipsis } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import { addComment, updateComment } from '@/graphql/mutations'
import { getCaretPosition, setCaretPosition, pasteHtmlAtCaret } from '@/utils/caretHelper'
import LimitElements from '@/components/misc/LimitElements.vue'
import DocumentViewer from '@/components/misc/DocumentViewer.vue'
import useCommentBox from './useCommentBox'
import errorHandling from '@/utils/errorHandling'
import dropFile from '@/utils/dropFile'
import useJob from '@/composables/useJob'
import useTeam from '@/composables/useTeam'
import useProspect from '@/composables/useProspect'

export default {
  components: {
    LimitElements,
    DocumentViewer,
  },
  props: {
    jobId: {
      type: Number,
      default: null,
    },
    taskId: {
      type: Number,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: null,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const comment = ref('')
    const showMentionBox = ref(false)
    const commentBox = ref(null)
    const loadingSubmit = ref(false)
    const loadingSearch = ref(false)
    const isOpen = ref(true)

    const caretPos = ref(0)
    const mentionPos = ref(0)
    const updateCaretPosition = () => {
      setTimeout(() => {
        caretPos.value = getCaretPosition(commentBox.value).pos
      }, 100)
    }

    const searchKeyword = ref('')
    const mentionResult = ref({
      users: [],
      jobs: [],
      prospects: [],
      teams: [],
    })

    const filterJob = computed(() => ({
      search: searchKeyword.value,
    }))
    const { jobList, fetchJobs } = useJob({
      filter: filterJob,
    })
    const { searchUsersTeamsResults, searchUsersTeams } = useTeam()
    const { prospectList, fetchProspect } = useProspect()

    const searchObject = useDebounceFn(async () => {
      loadingSearch.value = true

      if (searchKeyword.value.length > 2) {
        await fetchJobs()
        mentionResult.value.jobs = jobList.value

        await fetchProspect({
          search: searchKeyword.value,
        })
        mentionResult.value.prospects = prospectList.value

        await searchUsersTeams(searchKeyword.value, 6)

        mentionResult.value.users = searchUsersTeamsResults.value.users.map(el => ({
          ...el,
          name: `${el.first_name} ${el.last_name}`,
        }))
        mentionResult.value.teams = searchUsersTeamsResults.value.teams
      }

      loadingSearch.value = false
    }, 500)

    const onInput = input => {
      const inputCollection = input.target.innerHTML.split('')
      comment.value = input.target.innerHTML

      // only show mention box if it starts with space and followed by '@'
      if (input.data === '@') {
        const index = inputCollection.lastIndexOf('@')
        if (index === 0 || inputCollection.slice(index - 1, index)[0] === ' ' || inputCollection.slice(index - 1, index)[0] === ';') {
          showMentionBox.value = true
          setTimeout(() => {
            mentionPos.value = getCaretPosition(commentBox.value).pos
          }, 100)
        }
      }

      if (showMentionBox.value) {
        if (input.data && input.data !== '@') {
          searchKeyword.value += input.data
        }

        searchObject()
      }
    }

    const onKeydown = key => {
      if (key.which === 27) {
        showMentionBox.value = false
      }

      if (key.which === 8) {
        searchKeyword.value = searchKeyword.value.substring(searchKeyword.value.length - 1, 0)

        if (!searchKeyword.value) {
          showMentionBox.value = false
        }
      }

      if (key.which === 13 && !key.shiftKey) {
        key.preventDefault()
        if (!showMentionBox.value && comment.value.length) {
          showMentionBox.value = false

          // eslint-disable-next-line
          submitComment()
        }
      }

      updateCaretPosition()
    }

    const reset = () => {
      mentionResult.value = {
        users: [],
        jobs: [],
        prospects: [],
        teams: [],
      }
      searchKeyword.value = ''
    }

    watch(showMentionBox, value => {
      if (!value) reset()
    })

    const selectMention = (data, type) => {
      pasteHtmlAtCaret(`<div data-type="${type}" contenteditable="false" class="mention ${type}" data-id="${data.id}"><span class="mention-text">${data.name}</span></div>&nbsp;`)
      commentBox.value.innerHTML = [commentBox.value.innerHTML.replace(`@${searchKeyword.value}`, ''), ' '].join('')

      showMentionBox.value = false
      searchKeyword.value = ''

      setCaretPosition(commentBox.value, mentionPos.value + data.name.length + 1)
    }

    const {
      documents,
      onDragOver,
      onDrop,
      onDragLeave,
      onFileChange,
      deleteDoc,
    } = dropFile()

    const { encodeComment, findMention } = useCommentBox()

    const renderInitialComment = () => {
      const parsed = JSON.parse(props.content.comment)

      if (!parsed) return false

      const renderedComment = parsed.map(el => {
        if (typeof el === 'object') return `<div data-type="user" contenteditable="false" class="mention" data-id="${el.id}"><span class="mention-text">${el.label}</span></div>`

        // strip html tag
        return el.replace(/<\/?[^>]+(>|$)/g, '')
      }).join('')

      commentBox.value.innerHTML = renderedComment
      comment.value = renderedComment

      return true
    }

    onMounted(() => {
      if (props.toggleable) {
        isOpen.value = false
      }

      if (props.updateMode) {
        renderInitialComment()
      }
    })

    const submitComment = () => {
      if (comment.value.length) {
        const encodedComment = encodeComment(commentBox.value.innerHTML)
        if (!props.updateMode) {
          const variables = {
            job_id: props.jobId,
            task_id: props.taskId,
            comment: encodedComment,
            paramTagUser: findMention(commentBox.value.innerHTML),
            commentFile: documents.value.map(el => ({
              fileType: el.file.type,
              file: el.file,
              file_size_mb: Math.round(el.file.size / 100000) / 10,
            })),
          }
          loadingSubmit.value = true
          apolloClient.mutate({
            mutation: addComment,
            variables,
          }).then(result => {
            loadingSubmit.value = false
            commentBox.value.innerHTML = ''
            comment.value = ''
            documents.value = []
            emit('submit', result)
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        } else {
          const variables = {
            id: props.content.id,
            comment: encodedComment,
          }
          loadingSubmit.value = true
          apolloClient.mutate({
            mutation: updateComment,
            variables,
          }).then(result => {
            loadingSubmit.value = false
            emit('updated', result)
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        }
      }
    }

    // speech recognition
    const {
      isSupported,
      isListening,
      isFinal,
      result,
      start,
      stop,
    } = useSpeechRecognition({
      lang: 'id-ID',
    })

    watch(result, useDebounceFn(data => {
      commentBox.value.innerHTML = `${commentBox.value.innerHTML} ${data}`
    }, 1000))

    return {
      loadingSubmit,
      loadingSearch,
      documents,
      commentBox,
      onInput,
      onKeydown,
      showMentionBox,
      selectMention,
      getCaretPosition,
      setCaretPosition,
      caretPos,
      mentionPos,
      updateCaretPosition,
      isOpen,
      searchKeyword,
      mentionResult,

      onDragOver,
      onDragLeave,
      onDrop,
      onFileChange,
      deleteDoc,

      submitComment,

      icons: {
        mdiAttachment,
        mdiPlus,
        mdiMicrophone,
        mdiCommentPlus,
        mdiChevronDown,
        mdiClose,
      },

      ellipsis,

      isSupported,
      isListening,
      isFinal,
      result,
      start,
      stop,
    }
  },
}
</script>

<style lang="scss">
@import './style.scss';
</style>

<style lang="scss" scoped>
@import '@/styles/pulse.scss';
@include pulseAnimation("red", 8px, #ff4c51)
</style>
