<template>
  <div>
    <v-chip
      v-if="status.name === 'Won'"
      :small="!xSmall"
      :x-small="xSmall"
      color="success"
      class="success--text font-weight-bold v-chip-light-bg"
    >
      Won
    </v-chip>
    <v-chip
      v-if="status.name === 'Lost'"
      :small="!xSmall"
      :x-small="xSmall"
      color="error"
      class="error--text font-weight-bold v-chip-light-bg"
    >
      Lost
    </v-chip>
    <v-chip
      v-if="status.name === 'Cold'"
      :small="!xSmall"
      :x-small="xSmall"
      color="info"
      class="info--text font-weight-bold v-chip-light-bg"
    >
      Cold
    </v-chip>
    <v-chip
      v-if="status.name === 'Hot'"
      :small="!xSmall"
      :x-small="xSmall"
      color="warning"
      class="warning--text font-weight-bold v-chip-light-bg"
    >
      Hot
    </v-chip>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ChipDisplay',
  props: {
    status: {
      default: () => {},
      type: Object,
    },
    xSmall: {
      default: false,
      type: Boolean,
    },
  },
})
</script>
