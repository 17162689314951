<template>
  <v-card
    ref="dropArea"
    outlined
    class="drop-file-input"
    :ripple="false"
    @click="files.length < +maxFile ? $refs.dropFileInput.click() : null"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <perfect-scrollbar
      :options="{
        wheelPropagation: true,
        useBothWheelAxes: true,
        suppressScrollY: true,
      }"
      class="h-full"
    >
      <div
        class="d-flex align-center h-full"
      >
        <draggable
          :list="files"
          :animation="200"
          ghost-class="ghost-card"
          group="value"
          class="d-flex"
          :sort="sortable"
          @change="updateSort"
        >
          <v-hover
            v-for="(file, index) in files"
            :key="index"
            #default="{ hover }"
          >
            <v-card
              outlined
              class="file"
            >
              <v-img
                v-if="file.isDummy || file.file.type.includes('image')"
                class="rounded"
                height="152"
                width="180"
                :src="file.preview ? file.preview : 'http://placekitten.com/140/110'"
              />
              <div
                v-else
                class="d-flex flex-column h-full w-full justify-center align-center"
              >
                <v-icon>
                  {{ icons.mdiFile }}
                </v-icon>
                <span class="text-caption text--disabled text-truncate mt-1">
                  {{ ellipsis(file.file.name, 16) }}
                </span>
              </div>
              <v-slide-y-reverse-transition mode="out-in">
                <div
                  v-if="hover"
                  class="file-action d-flex flex-wrap w-full justify-center"
                >
                  <v-btn
                    v-if="!disablePreview"
                    icon
                    elevation="2"
                    color="primary"
                    class="mr-1"
                    style="background-color: #fff"
                    @click.stop="$refs.docViewer.show(file.isDummy ? file.type : file.file.type, file.preview)"
                  >
                    <v-icon>
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                  <v-tooltip
                    v-if="files.length > 1 && sortable"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        elevation="2"
                        color="secondary"
                        class="mr-1"
                        style="background-color: #fff"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop
                      >
                        <v-icon>
                          {{ icons.mdiArrowLeftRight }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Geser Urutan</span>
                  </v-tooltip>
                  <v-btn
                    icon
                    elevation="2"
                    color="error"
                    class="ml-1"
                    style="background-color: #fff"
                    @click.stop="removeFile(file.id)"
                  >
                    <v-icon>
                      {{ icons.mdiTrashCan }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-slide-y-reverse-transition>
            </v-card>
          </v-hover>
        </draggable>
        <v-card
          v-if="files.length && files.length < +maxFile"
          outlined
          class="file d-flex align-center justify-center flex-shrink-0"
          height="152"
          width="180"
          @click.stop="$refs.dropFileInput.click()"
        >
          <v-icon
            size="28"
            class="text--disabled"
          >
            {{ icons.mdiPlus }}
          </v-icon>
        </v-card>
        <div
          v-else-if="files.length === 0"
          class="d-flex flex-column align-center mx-auto"
        >
          <span>{{ label }}</span>
          <span class="mt-1 text--disabled">{{ subtitle }}</span>
        </div>
      </div>
    </perfect-scrollbar>
    <input
      ref="dropFileInput"
      type="file"
      class="d-none"
      multiple
      :accept="accept"
      @change="onFileChange"
    >
    <document-viewer ref="docViewer" />
  </v-card>
</template>

<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import {
  mdiPlus, mdiTrashCan, mdiEyeOutline, mdiFile, mdiArrowLeftRight,
} from '@mdi/js'
import draggable from 'vuedraggable'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { ellipsis } from '@core/utils/filter'
import DocumentViewer from '@/components/misc/DocumentViewer.vue'

export default {
  components: {
    draggable,
    PerfectScrollbar,
    DocumentViewer,
  },
  props: {
    accept: {
      type: String,
      default: null,
    },
    maxSize: {
      type: [String, Number],
      default: null,
    },
    maxFile: {
      type: [String, Number],
      default: null,
    },
    duplicateCheck: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Taruh file disini.',
    },
    subtitle: {
      type: String,
      default: null,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    disablePreview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputData = computed(() => props.value)
    const files = ref([])
    const dropArea = ref(null)

    const validateFile = file => {
      if (!props.accept) return true

      const types = props.accept.split(',')
      const mimeType = file.type
      const baseMimeType = mimeType.replace(/\/.*$/, '')

      // eslint-disable-next-line
      for (let validType of types) {
        validType = validType.trim()
        if (validType.charAt(0) === '.') {
          if (
            file.name
              .toLowerCase()
              .indexOf(
                validType.toLowerCase(),
                file.name.length - validType.length,
              ) !== -1
          ) {
            return true
          }
        } else if (/\/\*$/.test(validType)) {
          // This is something like a image/* mime type
          if (baseMimeType === validType.replace(/\/.*$/, '')) {
            return true
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (mimeType === validType) {
            return true
          }
        }
      }

      return false
    }

    const addFile = (id, file, order, isDummy = false, type) => {
      let preview = isDummy ? file : null

      if (file.type) preview = URL.createObjectURL(file)
      let value = {
        id,
        file,
        order,
        isDummy,
        preview,
      }

      if (props.accept && !isDummy && !validateFile(file)) {
        Vue.notify({
          title: 'Jenis File Tidak Valid',
          text: 'Tidak bisa mengunggah jenis file tersebut!',
          type: 'error',
        })
        emit('fileInvalid', {
          error: 'accept',
          value,
        })

        return
      }

      if (props.maxFile && files.value.length >= +props.maxFile) {
        Vue.notify({
          title: 'Jumlah File Tidak Valid',
          text: 'Jumlah file melebihi batas!',
          type: 'error',
        })
        emit('fileInvalid', {
          error: 'maxFile',
          value,
        })

        return
      }

      if (props.duplicateCheck && !isDummy && files.value.some(el => el.file === file)) {
        Vue.notify({
          title: 'File Duplikat',
          text: 'File tersebut sudah diinputkan!',
          type: 'error',
        })
        emit('fileInvalid', {
          error: 'duplicateCheck',
          value,
        })

        return
      }

      if (props.maxSize && file.size / 1000 >= +props.maxSize) {
        Vue.notify({
          title: 'Ukuran File Tidak Valid',
          text: 'Ukuran file melebihi batas!',
          type: 'error',
        })
        emit('fileInvalid', {
          error: 'maxSize',
          value,
        })

        return
      }

      if (isDummy) {
        value = {
          ...value,
          type,
        }
      }

      emit('fileAdded', value)
      files.value.push(value)
    }

    const removeFile = id => {
      Vue.$dialog({
        title: 'Hapus file?',
        body: 'Yakin ingin hapus file?',
      }).then(result => {
        emit('fileDeleted', files.value.find(el => el.id === id))
        if (result) files.value = files.value.filter(el => el.id !== id)
      })
    }

    const onFileChange = event => {
      for (let i = 0; i < event.target.files.length; i += 1) {
        const id = Math.floor(Math.random() * 9999)
        addFile(id, event.target.files[i], files.value.length + 1, false)
      }
    }

    const onDragOver = event => {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('dragBorder')) {
        event.currentTarget.classList.add('dragBorder')
      }
    }

    const onDragLeave = event => {
      event.currentTarget.classList.remove('dragBorder')
    }

    const onDrop = event => {
      event.preventDefault()
      for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
        const id = Math.floor(Math.random() * 9999)
        addFile(id, event.dataTransfer.files[i], files.value.length + 1, false)
      }
      event.currentTarget.classList.remove('dragBorder')
    }

    const updateSort = event => {
      emit('fileMoved', event)
      files.value = files.value.map((file, index) => ({
        ...file,
        order: index + 1,
      }))
    }

    const getAcceptedFiles = () => files.value

    const reset = () => {
      files.value = []
    }

    return {
      inputData,
      files,
      dropArea,
      onFileChange,
      onDragOver,
      onDragLeave,
      onDrop,
      addFile,
      removeFile,
      updateSort,
      getAcceptedFiles,
      reset,

      icons: {
        mdiPlus,
        mdiTrashCan,
        mdiEyeOutline,
        mdiFile,
        mdiArrowLeftRight,
      },

      ellipsis,
    }
  },
}
</script>

<style lang="scss">
.drop-file-input {
  height: 160px !important;

  .file {
    height: 152px !important;
    width: 180px;
    margin-left: 4px;
  }
  .ghost-card {
    background: rgb(240, 240, 240) !important;
  }
  .dragBorder {
    border-color: var(--v-primary-base) !important;
    transition: all 0.3s ease;
  }
  .file-action {
    position: absolute;
    left: 0;
    bottom: 52px;
  }
}
</style>
