import { ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { referenceLogs } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'

const useTimeline = objectId => {
  const timelineLoading = ref(false)
  const timelineLoadingPagination = ref(false)
  const timelines = ref([])
  const timelineCount = ref(0)
  const paramsData = ref(objectId)
  const pagination = ref({
    offset: 0,
    limit: 20,
  })

  const fetchReferenceLogs = async fetchMore => {
    timelineLoading.value = true
    if (fetchMore) {
      pagination.value.offset += 10
    }
    apolloClient.query({
      query: referenceLogs,
      fetchPolicy: 'no-cache',
      variables: {
        ...paramsData.value,
        pagination: pagination.value,
      },
    }).then(({ data }) => {
      timelines.value.push(...data.referenceLogs.referenceLogs)
      timelineCount.value = data.referenceLogs.count
      timelineLoading.value = false
    }).catch(err => {
      errorHandling(err)
      timelineLoading.value = false
    })
  }

  const listenScrollFetchMoreTimeline = useDebounceFn(async data => {
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 100)
      && pagination.value.offset <= timelineCount.value
    ) {
      pagination.value.offset += 10
      timelineLoadingPagination.value = true
      await fetchReferenceLogs(true)
      timelineLoadingPagination.value = false
    }
  }, 200)

  return {
    timelines,
    timelineCount,
    timelineLoading,
    timelineLoadingPagination,

    fetchReferenceLogs,
    listenScrollFetchMoreTimeline,
  }
}

export default useTimeline
