<template>
  <v-autocomplete
    ref="userTeamAutoSuggest"
    v-model="inputData"
    placeholder="Cari pengguna atau tim..."
    :label="label"
    outlined
    dense
    deletable-chips
    chips
    :attach="attach"
    return-object
    clearable
    :multiple="multiple"
    :loading="loadingSearchUsersTeams"
    :items="options"
    item-value="id"
    item-text="name"
    small-chips
    hide-details="auto"
    no-filter
    :rules="inputRules"
    no-data-text="Pengguna atau tim tidak ditemukan"
    @update:search-input="onSearch"
  >
    <template #item="data">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-img
            v-if="data.item.photo"
            :src="data.item.photo"
          />
          <span v-else>{{ avatarText(data.item.name) }}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{ data.item.phone_number }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template #selection="data">
      <v-chip
        :color="data.item.team ? 'info' : 'primary'"
        small
        close
        class="mt-1"
      >
        {{ data.item.name }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { watch, computed } from '@vue/composition-api'
import { minArrayLength, maxArrayLength } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import useTeam from '@/composables/useTeam'

export default {
  props: {
    value: {
      type: [Array, Object, Number, String],
      default: null,
    },
    label: {
      type: String,
      default: 'Pilih pengguna / tim',
    },
    attach: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    min: {
      type: [Number, String],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    showTeams: {
      type: Boolean,
      default: false,
    },
    teamOnly: {
      type: Boolean,
      default: false,
    },
    visibilityId: {
      type: Number,
      default: 6,
    },
    jobTypeId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        return emit('input', val)
      },
    })

    const {
      loadingSearchUsersTeams, searchUsersTeamsResults, searchUsersTeams,
    } = useTeam()

    const inputRules = computed(() => {
      if (props.multiple && (props.min || props.max)) {
        const multipleRules = []

        if (props.max) multipleRules.push(maxArrayLength(inputData.value, props.max))
        if (props.min) multipleRules.push(minArrayLength(inputData.value, props.min))

        return [...props.rules, ...multipleRules]
      }

      return props.rules
    })

    const onSearch = data => {
      searchUsersTeams(data, props.visibilityId, props.jobTypeId)
    }

    const options = computed(() => {
      const { users, teams } = searchUsersTeamsResults.value
      let data = users

      if (props.showTeams && !props.teamOnly) {
        data = [
          { header: 'Pengguna' },
          ...users.map(el => ({
            ...el,
            id: `user-${el.id}`,
            team: false,
          })),
          { divider: true },
          { header: 'Tim' },
          ...teams.map(el => ({
            ...el,
            id: `team-${el.id}`,
            team: true,
          })),
        ]
      }

      if (props.showTeams && props.teamOnly) {
        data = teams
      }

      return data
    })

    const filterVisibilityProps = computed(() => props.visibilityId)

    watch(filterVisibilityProps, () => {
      searchUsersTeams('', props.visibilityId, props.jobTypeId)
    })

    return {
      inputData,
      loadingSearchUsersTeams,
      searchUsersTeamsResults,
      inputRules,
      options,

      onSearch,

      searchUsersTeams,

      avatarText,
    }
  },
}
</script>

<style>

</style>
