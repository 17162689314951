import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { ref, watch } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { deleteComment as deleteCommentMutation } from '@/graphql/mutations'
import { commentFiles, comments, commentSearch } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'

const useComment = ({
  pagination, search, searchFile, jobId, taskId,
} = {}) => {
  const commentListOri = ref([])
  const commentList = ref([])
  const commentListSearch = ref([])
  const commentFileList = ref([])
  const commentCount = ref(0)
  const matchesCount = ref(0)
  const commentFetches = ref(0)
  const commentFilter = ref({
    pagination: {
      limit: 20,
      offset: 0,
    },
    search: null,
  })
  const loadingComments = ref(false)
  const loadingFiles = ref(false)

  const commentListRef = ref()
  const isInputEnable = ref(false)
  const currentTabComment = ref(0)
  const showSearch = ref(false)
  const isSearching = ref(false)
  const scrollTop = ref(0)
  const scrollHeight = ref(0)

  watch(currentTabComment, val => {
    if (val === 1) {
      isInputEnable.value = false
    }
  })

  const calculateScrollHeight = () => {
    const el = commentListRef.value

    if (el) {
      scrollHeight.value = el.scrollHeight
      scrollTop.value = el.scrollTop
    }
  }

  const initializeScroll = () => {
    const el = commentListRef.value

    if (el) {
      el.scroll({ top: el.scrollHeight, behavior: 'smooth' })
      calculateScrollHeight()
    }
  }

  const sortComment = () => {
    commentListOri.value = commentListOri.value.sort(
      (a, b) => new Date(Date.parse(a.created_at)) - new Date(Date.parse(b.created_at)),
    )
  }

  const replaceWithSearchedComment = () => {
    commentList.value = commentListOri.value.map(
      comment => commentListSearch.value.find(sComment => sComment.id === comment.id) || comment,
    )
  }

  const pushComment = list => {
    const filteredList = list.filter(el => !commentListOri.value.some(c => c.id === el.id))
    const updatedList = commentListOri.value.map(el => {
      const findOnNewList = list.find(l => el.id === l.id)

      if (findOnNewList) return findOnNewList

      return el
    })
    commentListOri.value = [...updatedList, ...filteredList]
  }

  const fetchComments = async ({ fetchMore = false } = {}) => {
    loadingComments.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: comments,
          fetchPolicy: 'no-cache',
          variables: {
            pagination: pagination && !fetchMore ? pagination : commentFilter.value.pagination,
            ...(jobId && { job_id: jobId }),
            ...(taskId && { task_id: taskId }),
          },
        })
        .then(result => {
          loadingComments.value = false
          commentCount.value = result.data.comments.count
          resolve(result.data.comments.comments)
          if (fetchMore) {
            pushComment(result.data.comments.comments)
          } else {
            commentListOri.value = result.data.comments.comments
          }

          sortComment()

          commentList.value = commentListOri.value
        })
        .catch(err => {
          loadingComments.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchCommentsMore = async () => {
    commentFilter.value.pagination.offset += 20
    commentFetches.value += 1
    const data = await fetchComments({ fetchMore: true })

    if (commentListSearch.value.length) {
      replaceWithSearchedComment()
    }

    return data
  }

  const searchComment = async () => {
    loadingComments.value = true
    await apolloClient
      .query({
        query: commentSearch,
        fetchPolicy: 'no-cache',
        variables: {
          ...(jobId && { job_id: jobId }),
          ...(taskId && { task_id: taskId }),
          search: search && search.value ? search.value : commentFilter.value.search,
        },
      })
      .then(result => {
        loadingComments.value = false
        matchesCount.value = result.data.commentSearch.count
        commentListSearch.value = result.data.commentSearch.comments

        replaceWithSearchedComment()
      })
      .catch(err => {
        loadingComments.value = false
        errorHandling(err)
      })
  }

  const fetchCommentFiles = async () => {
    loadingFiles.value = true

    await apolloClient.query({
      query: commentFiles,
      fetchPolicy: 'no-cache',
      variables: {
        pagination: {
          limit: 100,
          offset: 0,
        },
        filter: {
          ...(jobId && { job_id: jobId }),
          ...(taskId && { task_id: taskId }),
          ...(searchFile && searchFile.value && { search: searchFile.value }),
        },
      },
    }).then(result => {
      loadingFiles.value = false
      commentFileList.value = result.data.commentFiles
    }).catch(err => {
      loadingFiles.value = false
      errorHandling(err)
    })
  }

  const resetComments = async () => {
    commentListSearch.value = []
    matchesCount.value = 0
    commentList.value = commentListOri.value
  }

  const listenScrollFetchMoreComment = useDebounceFn(async data => {
    if (data.target.scrollTop === 0 && commentFilter.value.pagination.offset <= commentCount.value) {
      await fetchCommentsMore()
    }
  }, 200)

  const deleteComment = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus komentar ini?',
      body: 'Anda yakin ingin menghapus komentar ini?',
    }).then(confirm => {
      if (confirm) {
        loadingComments.value = true

        apolloClient
          .mutate({
            mutation: deleteCommentMutation,
            variables: {
              id,
            },
          })
          .then(result => {
            loadingComments.value = false

            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus komentar.',
            })
            fetchComments()

            resolve(result)
          })
          .catch(err => {
            loadingComments.value = false

            reject(err)
            errorHandling(err, 'Hapus Komentar')
          })
      } else {
        reject()
      }
    })
  })

  return {
    commentList,
    commentListSearch,
    commentFileList,
    commentCount,
    matchesCount,
    loadingComments,
    loadingFiles,

    commentListRef,
    isInputEnable,
    currentTabComment,
    showSearch,
    isSearching,
    scrollHeight,
    scrollTop,

    calculateScrollHeight,
    initializeScroll,
    fetchComments,
    fetchCommentsMore,
    searchComment,
    fetchCommentFiles,
    resetComments,
    listenScrollFetchMoreComment,
    deleteComment,
  }
}

export default useComment
