<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="text-subtitle-2"
    v-html="renderedHTML"
  />
</template>

<script>
export default {
  props: {
    comment: {
      type: String,
      default: null,
    },
  },
  computed: {
    renderedHTML() {
      const parsed = JSON.parse(this.comment)

      const renderToHTML = parsed.map(el => {
        if (typeof el === 'object') {
          let router = { name: 'user-detail', params: { id: el.id } }

          if (el.type === 'url') {
            return `<a href=${el.url} target="_blank"><div class="mention">${el.label}</div></a>`
          }

          if (el.type === 'email') {
            return `<a href='mailto:${el.label}' target="_blank"><div class="mention">${el.label}</div></a>`
          }

          if (el.type === 'phone') {
            return `<a href='tel:${el.phone}' target="_blank"><div class="mention">${el.label}</div></a>`
          }

          if (el.type === 'team') {
            router = { name: 'teams' }
          }

          if (el.type === 'job') {
            router = { name: 'job-detail', params: { id: el.id } }
          }

          if (el.type === 'prospect') {
            router = { name: 'prospect-detail', params: { id: el.id } }
          }

          const hrefURL = this.$router.resolve(router).href

          return `<a href=${hrefURL} target="_blank"><div class="mention ${el.type}">@${el.label}</div></a>`
        }

        return el
      })

      return renderToHTML.join('')
    },
  },
}
</script>

<style>

</style>
