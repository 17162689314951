import gql from 'graphql-tag'

const newChat = gql`
  subscription($workspace_id: Float!) {
    newChat(workspace_id: $workspace_id) {
      id
      message_id
      content
      type
      sender {
        id
        name
      }
      reply_to
      file_id
      file_url
      caption
      additional_data
      file_name
      file_size
      read
      room {
        id
        third_party_room_id
      }
      assign_user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      created_at
      updated_at
    }
  }
`

export default newChat
