import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.expand)?_c(VMenu,{attrs:{"close-on-content-click":false,"close-on-click":false,"top":"","right":"","offset-y":"","nudge-top":"20","nudge-left":"20px","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"comment-button"},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCommentMultiple)+" ")])],1)]}}],null,false,2092740857),model:{value:(_vm.showCommentBox),callback:function ($$v) {_vm.showCommentBox=$$v},expression:"showCommentBox"}},[_c('comment-list',{staticStyle:{"width":"640px"},attrs:{"job-id":_vm.jobId,"task-id":_vm.taskId,"expand":_vm.expand,"is-subscribed":_vm.isSubscribed},on:{"close":_vm.close,"changeSubscription":_vm.toggleNotification},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("head")]},proxy:true}],null,true)})],1):_c('comment-list',{attrs:{"job-id":_vm.jobId,"task-id":_vm.taskId,"expand":_vm.expand,"expand-height":_vm.expandHeight,"outlined":_vm.outlined,"toggleable":_vm.toggleable,"is-subscribed":_vm.isSubscribed},on:{"close":_vm.close,"changeSubscription":_vm.toggleNotification},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("header")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }