<template>
  <div>
    <p
      v-if="days > 0"
      :class="`mb-0 ${customClass}`"
    >
      {{ days }} hari, {{ hours }} jam, {{ minutes }} menit
    </p>
    <p
      v-else
      :class="`mb-0 ${customClass}`"
    >
      {{ hours ? hours : '00' }}:{{ minutes ? minutes : '00' }}:{{ seconds ? seconds : '00' }}
    </p>
  </div>
</template>
<script>
import {
  defineComponent, onMounted, ref, onUnmounted,
} from '@vue/composition-api'

const MILLISECONDS_SECOND = 1000
const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE
const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR
const MILLISECONDS_WEEK = 7 * MILLISECONDS_DAY
export default defineComponent({
  props: {
    date: {
      type: String,
      default: () => null,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const totalMilis = ref()
    const endMilis = ref()
    const initMilis = ref()
    const interval = ref()
    const days = ref()
    const hours = ref()
    const minutes = ref()
    const seconds = ref()
    const minTwoDigits = n => (n < 10 ? '0' : '') + n
    const updateDay = () => {
      days.value = Math.floor((totalMilis.value % MILLISECONDS_WEEK) / MILLISECONDS_DAY)
    }
    const updateHour = () => {
      if (days.value < 1) hours.value = minTwoDigits(Math.floor((totalMilis.value % MILLISECONDS_DAY) / MILLISECONDS_HOUR))
      else hours.value = Math.floor((totalMilis.value % MILLISECONDS_DAY) / MILLISECONDS_HOUR)
    }
    const updateMinute = () => {
      if (days.value < 1) minutes.value = minTwoDigits(Math.floor((totalMilis.value % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE))
      else minutes.value = Math.floor((totalMilis.value % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE)
    }
    const updateSecond = () => {
      if (days.value < 1) seconds.value = minTwoDigits(Math.floor((totalMilis.value % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND))
      else seconds.value = Math.floor((totalMilis.value % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND)
    }

    const updateTime = () => {
      const now = new Date().getTime()
      const end = new Date(props.date).getTime()
      const totalMilisecond = end - now
      if (!initMilis.value) initMilis.value = totalMilisecond
      if (!endMilis.value) endMilis.value = end
      if (totalMilisecond > 0) {
        totalMilis.value = totalMilisecond
        updateDay()
        updateHour()
        updateMinute()
        updateSecond()
        emit('update', {
          initMilis: initMilis.value,
          endMilis: endMilis.value,
          totalMilis: totalMilis.value,
          days: days.value,
          hours: hours.value,
          minutes: minutes.value,
          seconds: seconds.value,
        })
      } else {
        clearInterval(interval.value)
      }
    }

    onMounted(() => {
      if (props.date) updateTime()
      interval.value = setInterval(() => {
        if (props.date) updateTime()
      },
      1000)
    })

    onUnmounted(() => {
      clearInterval(interval.value)
    })

    return {
      totalMilis,
      days,
      hours,
      minutes,
      seconds,
    }
  },
})
</script>
