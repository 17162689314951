<template>
  <v-text-field
    v-model="modal"
    dense
    readonly
    outlined
    class="mb-0 width centered-input"
  >
    <template #prepend-inner>
      <v-icon @click="remove">
        {{ icons.mdiMinus }}
      </v-icon>
    </template>
    <template #append>
      <v-icon @click="add">
        {{ icons.mdiPlus }}
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
/* eslint-disable no-multi-assign */
import {
  defineComponent, ref, watch,
} from '@vue/composition-api'
import { mdiPlus, mdiMinus } from '@mdi/js'

export default defineComponent({
  name: 'Counter',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    qty: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const modal = ref(props.qty)
    const add = () => {
      modal.value = modal.value += 1
    }
    const remove = () => {
      if (modal.value !== 1) modal.value = modal.value -= 1
    }
    watch(modal, () => {
      emit('changeQty', { id: props.id, qty: modal.value })
    })

    return {
      add,
      remove,
      modal,
      icons: {
        mdiPlus, mdiMinus,
      },
    }
  },
})
</script>
<style scoped>
.width{
  width: 110px !important;
}
.centered-input >>> input {
  text-align: center
}
</style>
