<template>
  <v-card
    class="pa-3 mb-0 d-flex flex-column"
  >
    <div class="d-flex justify-space-between mb-3">
      <span class="text-subtitle-1 font-medium">Order</span>
      <v-btn
        color="primary"
        x-small
      >
        Ubah
      </v-btn>
    </div>
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex flex-column">
        <span class="text-subtitle-2 primary--text font-medium">Order Name</span>
        <span class="text-caption text--disabled">Order Identifier</span>
      </div>
      <v-chip
        color="success"
        small
      >
        Platform
      </v-chip>
    </div>
  </v-card>
</template>

<script>
import { mdiChartAreaspline } from '@mdi/js'

export default {
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      icons: {
        mdiChartAreaspline,
      },
    }
  },
}
</script>

<style>

</style>
