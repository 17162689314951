import gql from 'graphql-tag'

const prospectSubscription = gql`
  subscription {
    prospectSubscription {
      id
      name
      lexorank
      status {
        id
        name
      }
    }
  }
`

export default prospectSubscription
