<template>
  <div>
    <div
      class="pa-3 mt-1 cursor-pointer"
      @click="$emit('detail', task.id)"
    >
      <div class="d-flex flex-column flex-md-row justify-space-between">
        <div
          class="d-flex align-start flex-grow-0 task-detail-left"
        >
          <v-btn
            icon
            class="mt-n2 ml-n1"
            @click.stop="$emit('check', task.id)"
          >
            <v-icon>
              {{ task.completed_at ? icons.mdiCheckboxMarked : icons.mdiCheckboxBlankOutline }}
            </v-icon>
          </v-btn>
          <div class="d-flex flex-column">
            <div class="d-flex flex-wrap">
              <h5 class="font-medium mb-1 mr-2">
                {{ task.subject }}
              </h5>
              <LimitElements
                :elements="task.tag"
                :limit="4"
              >
                <template #default="{ data }">
                  <v-chip
                    x-small
                    :color="data.color"
                    class="mr-1"
                  >
                    {{ data.name }}
                  </v-chip>
                </template>
                <template #others-activator="{ data }">
                  <v-chip
                    x-small
                    color="primary"
                    class="mr-1 v-chip-light-bg primary--text mb-2"
                  >
                    +{{ data.limit }}
                  </v-chip>
                </template>
              </LimitElements>
            </div>
            <span class="text-caption">
              {{ task.description }}
            </span>
          </div>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-caption text-md-right mt-1 mt-md-0"
              v-bind="attrs"
              v-on="on"
            >
              <!-- TODO: fix format date "Invalid time value"  -->
              <!-- {{ dateFormat(task.end_date, $vuetify.breakpoint.smAndDown ? 3 : 1) }} -->

              <!-- temporary remove formatting -->
              {{ task.end_date }}
            </span>
          </template>
          <div class="d-flex flex-column">
            <!-- TODO: fix format date "Invalid time value" -->
            <!-- <span>Dibuat: {{ dateFormat(task.created_at, 3) }}</span>
            <span>Diperbarui: {{ dateFormat(task.updated_at, 3) }}</span> -->

            <!-- temporary remove formatting -->
            <span>Dibuat: {{ task.created_at }}</span>
            <span>Diperbarui: {{ task.updated_at }}</span>
          </div>
        </v-tooltip>
      </div>
      <div class="d-flex flex-wrap justify-space-between align-end mt-1">
        <LimitElements
          v-if="task.assignedTo.length"
          :elements="task.assignedTo"
          :limit="3"
          class="v-avatar-group me-3"
          :class="rootThemeClasses"
        >
          <template #default="{ data }">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  class="v-avatar-light-bg"
                  size="36"
                  v-on="on"
                  @click.stop="$router.push({
                    name: 'user-detail',
                    params: { id: data.user.id }
                  })"
                >
                  <v-img
                    v-if="data.user.photo"
                    :src="data.user.photo"
                  />
                  <span v-else>{{ avatarText(data.user.name) }}</span>
                </v-avatar>
              </template>
              <span>{{ data.user.name }}</span>
            </v-tooltip>
          </template>
          <template #others="{ data }">
            <div
              class="d-flex align-center mb-1"
              @click.stop="$router.push({
                name: 'user-detail',
                params: { id: data.user.id }
              })"
            >
              <v-avatar
                class="v-avatar-light-bg mr-2"
                size="20"
              >
                <v-img
                  v-if="data.user.photo"
                  :src="data.user.photo"
                />
                <span
                  v-else
                  class="text-avatar-small"
                >{{ avatarText(data.user.name) }}</span>
              </v-avatar>
              <span class="text-subtitle-2">{{ data.user.name }}</span>
            </div>
          </template>
          <template #others-activator="{ data }">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  small
                  v-bind="attrs"
                  size="36"
                  color="white primary--text text-caption"
                  v-on="on"
                >
                  <span>+{{ data.limit }}</span>
                </v-avatar>
              </template>
              <span>Lihat User Lain</span>
            </v-tooltip>
          </template>
        </LimitElements>
        <div
          v-if="task.customer && !isJobProspectTask"
          class="d-sm-flex align-center d-none cursor-pointer"
          @click.stop="$router.push({
            name: 'customer-detail',
            params: { id: task.customer.id }
          })"
        >
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mr-2"
            size="36"
          >
            {{ avatarText(task.customer.name) }}
          </v-avatar>
          <div class="d-flex flex-column">
            <span class="text-caption primary--text font-medium">{{ task.customer.name }}</span>
            <span class="text-caption text--disabled">{{ task.customer.phone_number }}</span>
          </div>
        </div>
        <div class="d-flex flex-column ml-auto align-end">
          <div
            v-if="!isJobProspectTask"
            class="d-flex mb-n1"
          >
            <router-link
              v-if="task.prospect"
              class="text-caption primary--text"
              :to="{ name: 'prospect-detail', params: { id: task.prospect.id } }"
            >
              {{ task.prospect.name }}
            </router-link>
            <span
              v-if="task.prospect && task.job"
              class="text-caption mx-1"
            >/</span>
            <router-link
              v-if="task.job"
              class="text-caption primary--text"
              :to="{ name: 'job-detail', params: { id: task.job.id } }"
            >
              {{ task.job.name }}
            </router-link>
          </div>
          <div class="d-flex align-center">
            <v-switch
              class="ma-0"
              hide-details
              dense
              @click.stop
            />
            <v-tooltip
              top
              allow-overflow
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="20"
                  class="text--disabled"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ resolveIconVisibility(task.visibility.id) }}
                </v-icon>
              </template>
              <span class="d-block">Visibilitas: {{ task.visibility.name }}</span>
              <span>{{ task.visibility.description }}</span>
            </v-tooltip>
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="18"
                  class="ms-1 text--disabled"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click.stop="$emit('detail', task.id)">
                  Lihat Detail
                </v-list-item>
                <v-list-item
                  v-if="!isJobProspectTask && $can('update', 'Task')"
                  @click.stop="$emit('update', task.id)"
                >
                  Ubah Task
                </v-list-item>
                <v-list-item
                  v-if="$can('del', 'Task')"
                  class="error--text"
                  @click.stop="$emit('delete', task.id)"
                >
                  Hapus Task
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <v-divider />
  </div>
</template>

<script>
import {
  mdiDotsVertical, mdiCheckboxBlankOutline, mdiCheckboxMarked,
} from '@mdi/js'
import useVuetify from '@core/utils/vuetify'
import { avatarText } from '@core/utils/filter'
import LimitElements from '@/components/misc/LimitElements.vue'
import dateFormat from '@/utils/dateFormat'
import useVisibility from '@/composables/useVisibility'

export default {
  components: {
    LimitElements,
  },
  props: {
    isJobProspectTask: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup() {
    const { rootThemeClasses } = useVuetify()
    const { resolveIconVisibility } = useVisibility()

    return {
      rootThemeClasses,
      dateFormat,
      avatarText,
      resolveIconVisibility,

      icons: {
        mdiDotsVertical,
        mdiCheckboxBlankOutline,
        mdiCheckboxMarked,
      },
    }
  },
}
</script>

<style>

</style>
