<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    scrollable
    @input="!$event ? reset() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Prospek' : 'Tambah Prospek' }}</span>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="prospectForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <v-text-field
                v-model="formData.name"
                :rules="[required]"
                label="Nama Prospek (wajib diisi)"
                placeholder="Input nama prospek"
                outlined
                dense
                hide-details="auto"
              />
              <v-textarea
                v-model="formData.description"
                class="mt-4"
                :rules="[required]"
                label="Deskripsi Prospect"
                placeholder="Input deskripsi prospect"
                outlined
                dense
                hide-details="auto"
              />
              <CustomerAutoSuggest
                v-model="formData.customer"
                :value="formData.customer ? formData.customer : null"
                class="mt-4 mb-4"
                :rules="[required]"
              />
              <!-- <UserAutoSuggest
                v-model="formData.assignTo"
                multiple
                :show-teams="true"
                class="mt-4 mb-4"
                :label="'Ditugaskan kepada'"
              /> -->
              <p class="caption mb-2 ml-1">
                Status Prospek
              </p>
              <ChipGroup
                ref="chipGroup"
                v-model="prospectStatus"
                :disabled-chip-id="[1,2]"
                :value="prospectStatus"
                class="mb-4"
                @input="statusChange"
              />
              <p
                v-if="chipValidator"
                class="caption mb-2 ml-1 error--text"
              >
                Data ini wajib di isi
              </p>
              <DateTimePicker
                v-model="formData.expired_at"
                label="Tanggal Kadaluarsa"
                placeholder="Tanggal Kadaluarsa"
              />

              <VisibilityPicker
                ref="visibilityPicker"
                v-model="formData.visibility"
                class="mt-4 ml-1"
              />
              <UserTeamAutoSuggest
                v-model="formData.assignTo"
                class="mt-4 ml-1"
                label="Ditugaskan ke"
                show-teams
                multiple
                :visibility-id="formData.visibility"
              />
              <v-card
                v-if="!updateMode"
                outlined
                class="pa-3 mt-4 ml-1 d-flex flex-column"
              >
                <span class="text-subtitle-2 font-medium mb-3">Atribut Lainnya</span>
                <div
                  v-for="(attribute, index) in customAttributesArray"
                  :key="attribute.custom_attribute.id"
                  class="mb-2"
                >
                  <v-text-field
                    v-if="attribute.custom_attribute.data_type.id === 1 || attribute.custom_attribute.data_type.id === 2"
                    v-model="customAttributesArray[index].value"
                    :type="attribute.custom_attribute.data_type.id === 1 ? 'text' : 'number'"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                    outlined
                    dense
                    hide-details="auto"
                  />
                  <DateTimePicker
                    v-if="attribute.custom_attribute.data_type.id === 3"
                    v-model="customAttributesArray[index].value"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                  />
                  <v-select
                    v-if="attribute.custom_attribute.data_type.id === 4 || attribute.custom_attribute.data_type.id === 5"
                    v-model="customAttributesArray[index].value"
                    :items="attribute.custom_attribute.options"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="attribute.custom_attribute.data_type.id === 5"
                    :chips="attribute.custom_attribute.data_type.id === 5"
                    item-text="name"
                    item-value="id"
                  />
                </div>
                <v-btn
                  flat
                  elevation="0"
                  block
                  outlined
                  color="primary"
                  @click="$refs.formCustomAttribute.open()"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-col>
              <ProductAutoSuggest
                class="ml-1"
                :value="product"
                :emit-mode="true"
                :multiple="true"
                :rules="[required]"
                @input="productInput"
              />
              <PerfectScrollbar
                v-if="product.length"
                :key="productKey"
                class="ps-prospect-form"
              >
                <v-card
                  v-for="data in product"
                  :key="data.id"
                  class="mt-4"
                  outlined
                >
                  <v-card-text>
                    <div class="d-flex">
                      <div class="d-flex flex-column w-full">
                        <div class="d-flex justify-space-between w-full ">
                          <router-link
                            target="_blank"
                            :to="{ name: 'product-detail', params: { id: data.id} }"
                          >
                            <p class="body-2 font-weight-bold primary--text">
                              {{ data.name }}
                            </p>
                          </router-link>

                          <v-chip
                            small
                            color="warning"
                            class=" ml-4 warning--text font-weight-bold v-chip-light-bg"
                          >
                            Shopee
                          </v-chip>
                        </div>
                        <v-row dense>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <p class="mb-1 caption font-weight-bold">
                              Kode SKU
                            </p>
                            <p
                              class="caption"
                            >
                              {{ data.code_sku ? data.code_sku : "Tidak ada kode SKU" }}
                            </p>
                            <p class="mb-1 caption font-weight-bold">
                              Harga
                            </p>
                            <p
                              class="caption"
                            >
                              {{ data.price ? data.price : 0 }}
                            </p>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            class="d-flex flex-column align-end justify-end"
                          >
                            <p class="mb-1 caption font-weight-bold">
                              Kuantitas
                            </p>
                            <Counter
                              :id="data.id"
                              :qty="data.quantity ? data.quantity:1"
                              @changeQty="changeQuantity"
                            />
                            <v-icon
                              color="error"
                              @click="removeProduct(data.id)"
                            >
                              {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </PerfectScrollbar>
              <div
                v-else
                class="h-full d-flex align-center justify-center"
              >
                <p class="font-weight-semibold">
                  Pilih produk terlebih dahulu
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex flex-column flex-md-row mt-4">
                <v-btn
                  class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
                  outlined
                  @click="reset()"
                >
                  Reset Form
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="loadingSubmit"
                  @click="submitForm()"
                >
                  Simpan Prospek
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <custom-attribute-form
        ref="formCustomAttribute"
        :object-type-id="3"
        @update="fetchCustomAttributes()"
      />
    </v-card>
    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import Vue from 'vue'
import { ref, onMounted } from '@vue/composition-api'
import {
  mdiClose, mdiTrashCanOutline, mdiPlus, mdiCalendar,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { apolloClient } from '@/vue-apollo'
import router from '@/router'
import { prospectDetail } from '@/graphql/queries'
import { addProspect, updateProspect } from '@/graphql/mutations'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import ProductAutoSuggest from '@/components/inputs/ProductAutoSuggest.vue'
import Counter from '@/components/inputs/Counter.vue'
import errorHandling from '@/utils/errorHandling'
import VisibilityPicker from '@/components/inputs/VisibilityPicker.vue'
import store from '@/store'
import UserTeamAutoSuggest from '@/components/inputs/UserTeamAutoSuggest.vue'
import ChipGroup from '@/views/prospect/component/ChipGroup.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import CustomAttributeForm from '@/views/custom-attributes/CustomAttributeForm.vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'

export default {
  components: {
    UserTeamAutoSuggest,
    CustomerAutoSuggest,
    ProductAutoSuggest,
    PerfectScrollbar,
    Counter,
    VisibilityPicker,
    ChipGroup,
    CustomAttributeForm,
    DateTimePicker,
  },
  setup(props, { emit }) {
    const chipGroup = ref(null)
    const prospectForm = ref()
    const chipValidator = ref()
    const isOpen = ref(false)
    const assignedTo = ref([])
    const prospectStatus = ref()
    const formData = ref({
      name: '',
      description: '',
      prospect_product: null,
      customer: null,
      visibility: null,
    })
    const updateId = ref(null)
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const updateMode = ref(false)
    const product = ref([])
    const productKey = ref(0)
    const visibilityPicker = ref()
    const showExpired = ref(false)
    const { customAttributesArray, fetchCustomAttributes } = useCustomAttributes({ objectTypeId: 3 })

    const reset = () => {
      loadingSubmit.value = false
      loadingDetail.value = false
      product.value = []
      // reset
      formData.value = {
        name: '',
        description: '',
        prospect_product: null,
        customer: null,
        assignTo: null,
        visibility: 6,
      }
      assignedTo.value = []
      prospectStatus.value = null
      prospectForm.value.reset()
      customAttributesArray.value = null
    }

    onMounted(() => {
      // fetchCustomAttributes()
    })

    const show = data => {
      if (data) {
        formData.value.customer = data
      }
      isOpen.value = true
      // fetchCustomAttributes()
    }
    const close = () => {
      isOpen.value = false
      reset()
    }

    const productInput = (data, input) => {
      product.value = data
      formData.value.prospect_product = data.length ? data.map(val => ({ product_id: val.id, quantity: !input ? 1 : val.quantity })) : null
    }
    const changeQuantity = ({ id, qty }) => {
      const target = formData.value.prospect_product.find(val => val.product_id === id)
      target.quantity = qty
      const data = formData.value.prospect_product.filter(f => f.product_id !== target.id)
      formData.value.prospect_product = [...data, ...target]
    }
    const removeProduct = id => {
      product.value = product.value.filter(val => val.id !== id)
      formData.value.prospect_product = formData.value.prospect_product.filter(val => val.product_id !== id)
    }
    const statusChange = data => {
      prospectStatus.value = data
    }
    const update = id => {
      loadingDetail.value = true
      updateMode.value = true
      apolloClient.query({
        query: prospectDetail,
        fetchPolicy: 'no-cache',
        variables: {
          prospect_id: id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        updateId.value = id
        const resultData = result.data.prospectDetail
        formData.value = {
          name: resultData.name,
          description: resultData.description,
          prospect_product: null,
          order_id: null,
          expired_at: resultData.expired_at,
          customer: resultData.customer,
          assignTo: resultData.assignedTo.map(el => `user-${el.id}`),
          visibility: resultData.visibility.id,
        }
        prospectStatus.value = resultData.status.id
        const modifyProduct = resultData.product.map(val => ({ ...val.product, quantity: val.quantity }))
        productInput(modifyProduct, true)
        // eslint-disable-next-line array-callback-return
        console.log(resultData)
        loadingDetail.value = false
        show()
      }).catch(err => {
        console.log(err)
        errorHandling(err)
        loadingDetail.value = false
      })
    }

    const submitForm = () => {
      if (!prospectStatus.value) {
        chipValidator.value = true

        return
      }
      chipValidator.value = false
      if (prospectForm.value.validate()) {
        let visibilityUsers = []
        const teams = []
        const users = []
        if (formData.value.visibility === 5) {
          visibilityUsers = visibilityPicker.value.getUsersSelection()
        }
        formData.value.assignTo && formData.value.assignTo.length && formData.value.assignTo.map(value => {
          console.log(value)
          const ids = value.id ? value.id.split('-') : value.split('-')
          console.log(ids)
          if (ids[0] === 'team') teams.push(+ids[1])
          if (ids[0] === 'user') users.push(+ids[1])
        })
        loadingSubmit.value = true
        const payloadAttr = customAttributesArray.value.map(item => {
          let val
          if (item.custom_attribute.data_type.id === 3) val = item.value ? new Date(item.value) : null
          else if (item.custom_attribute.data_type.id === 4) val = item.value?.toString() || null
          else if (item.custom_attribute.data_type.id === 5) val = JSON.stringify(item.value || [])
          else val = item.value || null

          return {
            id: item.custom_attribute.id,
            value: val,
          }
        })
        console.log(payloadAttr)
        if (updateMode.value) {
          apolloClient.mutate({
            mutation: updateProspect,
            variables: {
              id: updateId.value,
              ...formData.value,
              prospect_status: prospectStatus.value,
              workspace_id: store.getters.getCurrentWorkspaceId,
              visibility: formData.value.visibility,
              customer: formData.value.customer.id,
              visibility_5_users: visibilityUsers.length && formData.value.visibility === 5 ? visibilityUsers : null,
              assign_user_id: users.length ? users : null,
              assign_team_id: teams.length ? teams : null,
            },
          }).then(async () => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengupdate prospek!',
            })
            close()
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        } else {
          apolloClient.mutate({
            mutation: addProspect,
            variables: {
              ...formData.value,
              prospect_status: prospectStatus.value,
              visibility: formData.value.visibility,
              visibility_5_users: visibilityUsers.length && formData.value.visibility === 5 ? visibilityUsers : null,
              customer: formData.value.customer.id,
              assign_user_id: users.length ? users : null,
              assign_team_id: teams.length ? teams : null,
              workspace_id: store.getters.getCurrentWorkspaceId,
              custom_attribute: payloadAttr,
            },
          }).then(async result => {
            const { id } = JSON.parse(result.data.addProspect.data)
            emit('success')
            loadingSubmit.value = false
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menambahkan prospek!',
            })
            close()
            router.push({ name: 'prospect-detail', params: { id } })
          }).catch(err => {
            loadingSubmit.value = false
            console.log(err)
            errorHandling(err)
          })
        }
      }
    }

    return {
      prospectForm,
      isOpen,
      formData,
      loadingSubmit,
      loadingDetail,
      updateMode,
      chipValidator,

      show,
      close,
      reset,
      update,
      submitForm,

      avatarText,
      productInput,
      product,
      productKey,
      changeQuantity,
      removeProduct,
      assignedTo,
      statusChange,
      prospectStatus,

      visibilityPicker,

      icons: {
        mdiTrashCanOutline,
        mdiClose,
        mdiPlus,
        mdiCalendar,
      },

      required,
      fetchCustomAttributes,
      customAttributesArray,
      chipGroup,
      showExpired,
    }
  },
}
</script>

<style>
.ps-prospect-form {
  max-height: 390px;
}
</style>
