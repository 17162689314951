<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    scrollable
    @input="!$event ? reset() : null"
  >
    <v-card v-if="isOpen">
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Task' : 'Task Baru' }}</span>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="taskForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="formData.subject"
                :rules="[required]"
                label="Judul Task (wajib diisi)"
                placeholder="Input judul task"
                outlined
                dense
                hide-details="auto"
              />
              <v-textarea
                v-model="formData.description"
                class="my-4"
                label="Deskripsi Task"
                placeholder="Input deskripsi task"
                outlined
                dense
                hide-details="auto"
              />
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mb-2 text-caption d-block">Waktu Mulai</span>
                  <DateTimePicker
                    v-model="formData.startDate"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mb-2 text-caption d-block">Waktu Selesai (Deadline)</span>
                  <DateTimePicker
                    v-model="formData.endDate"
                    :rules="[validateDeadline(formData.startDate, formData.endDate)]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <VisibilityPicker
                    v-if="isOpen"
                    ref="visibilityPicker"
                    v-model="formData.visibility"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="mt-n4"
                >
                  <span class="mb-2 mt-4 text-caption d-block">Ditugaskan ke</span>
                  <UserTeamAutoSuggest
                    v-model="formData.assign"
                    label="Ditugaskan ke"
                    show-teams
                    multiple
                    :visibility-id="formData.visibility"
                  />
                </v-col>
              </v-row>
              <span class="mb-2 mt-4 text-caption d-block">Tag Task</span>
              <tag-auto-suggest
                v-model="formData.tags"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                outlined
                class="pa-3 mb-4 d-flex flex-column"
              >
                <span class="text-subtitle-2 font-medium mb-3">Sambungkan Dengan Objek</span>
                <CustomerAutoSuggest
                  v-model="formData.customer"
                  label="Cari pelanggan"
                  class="mb-3"
                />
                <JobAutoSuggest
                  v-model="formData.job"
                  class="mb-2"
                  label="Cari job"
                  :filter-customer="formData.customer"
                  :disabled="!formData.customer"
                />
              </v-card>
              <v-card
                v-if="!updateMode"
                outlined
                class="pa-3 mb-4 d-flex flex-column"
              >
                <span class="text-subtitle-2 font-medium mb-3">Atribut Lainnya</span>
                <div
                  v-for="(attribute, index) in customAttributesArray"
                  :key="attribute.custom_attribute.id"
                  class="mb-2"
                >
                  <v-text-field
                    v-if="attribute.custom_attribute.data_type.id === 1 || attribute.custom_attribute.data_type.id === 2"
                    v-model="customAttributesArray[index].value"
                    :type="attribute.custom_attribute.data_type.id === 1 ? 'text' : 'number'"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                    outlined
                    dense
                    hide-details="auto"
                  />
                  <DateTimePicker
                    v-if="attribute.custom_attribute.data_type.id === 3"
                    v-model="customAttributesArray[index].value"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                  />
                  <v-select
                    v-if="attribute.custom_attribute.data_type.id === 4 || attribute.custom_attribute.data_type.id === 5"
                    v-model="customAttributesArray[index].value"
                    :items="attribute.custom_attribute.options"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="attribute.custom_attribute.data_type.id === 5"
                    :chips="attribute.custom_attribute.data_type.id === 5"
                    item-text="name"
                    item-value="id"
                  />
                </div>
                <v-btn
                  flat
                  elevation="0"
                  block
                  outlined
                  color="primary"
                  @click="$refs.formCustomAttribute.open()"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <div class="d-flex flex-column flex-md-row mt-4">
            <v-btn
              class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
              outlined
              @click="reset()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingSubmit"
              @click="submitForm()"
            >
              Simpan Task
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
      <custom-attribute-form
        ref="formCustomAttribute"
        :object-type-id="1"
        @update="fetchCustomAttributes()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose, mdiPlus } from '@mdi/js'
import { required, validateDeadline } from '@core/utils/validation'
import VisibilityPicker from '@/components/inputs/VisibilityPicker.vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import UserTeamAutoSuggest from '@/components/inputs/UserTeamAutoSuggest.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import JobAutoSuggest from '@/components/inputs/JobAutoSuggest.vue'
import { apolloClient } from '@/vue-apollo'
import { addTask, updateTask } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import TagAutoSuggest from '@/components/inputs/TagAutoSuggest.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import CustomAttributeForm from '@/views/custom-attributes/CustomAttributeForm.vue'

export default {
  components: {
    VisibilityPicker,
    DateTimePicker,
    UserTeamAutoSuggest,
    CustomerAutoSuggest,
    JobAutoSuggest,
    TagAutoSuggest,
    CustomAttributeForm,
  },
  setup(props, { emit }) {
    const formCustomAttribute = ref(null)
    const isOpen = ref(false)
    const updateMode = ref(false)
    const taskForm = ref()
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const formData = ref({
      id: null,
      subject: '',
      description: '',
      startDate: '',
      endDate: '',
      job: null,
      customer: null,
      visibility: null,
      tags: [],
      assign: [],
    })
    const visibilityPicker = ref()

    const { customAttributesArray, fetchCustomAttributes } = useCustomAttributes({ objectTypeId: 1 })

    const show = (valueCustomer, valueJob) => {
      if (valueCustomer) formData.value.customer = valueCustomer
      if (valueJob) formData.value.job = valueJob
      fetchCustomAttributes()
      isOpen.value = true
    }

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false
      customAttributesArray.value = null

      formData.value = {
        id: null,
        subject: '',
        description: '',
        startDate: '',
        endDate: '',
        job: null,
        customer: null,
        visibility: null,
        tags: [],
        assign: [],
      }

      taskForm.value.reset()
    }

    const close = () => {
      isOpen.value = false

      reset()
    }

    const update = task => {
      // fetchCustomAttributes(task.id).then()
      formData.value = {
        id: task.id,
        subject: task.subject,
        description: task.description,
        startDate: task.start_date,
        endDate: task.end_date,
        job: task.job ? task.job : null,
        customer: task.customer ? task.customer : null,
        visibility: task.visibility.id,
        tags: task.tag && task.tag.length ? task.tag.map(el => el.id) : [],
        assign: task.assignedTo && task.assignedTo.length ? task.assignedTo.map(el => `user-${el.user.id}`) : [],
      }
      isOpen.value = true
      updateMode.value = true

      setTimeout(() => {
        if (task.visibility.id === 5) {
          visibilityPicker.value.setUsersSelection(task.private_reference.map(el => el.user.id))
        }
      }, 300)
    }

    const submitForm = () => {
      if (taskForm.value.validate()) {
        loadingSubmit.value = true
        const data = formData.value

        let visibilityUsers = []
        const assignUserId = []
        const assignTeamId = []

        if (data.visibility === 5) {
          visibilityUsers = visibilityPicker.value.getUsersSelection()
        }

        if (data.assign.length) {
          data.assign.map(el => (el.id ? el.id : el)).forEach(el => {
            const split = el.split('-')
            if (split[0] === 'user') {
              assignUserId.push(+split[1])
            } else {
              assignTeamId.push(+split[1])
            }
          })
        }
        const payloadAttr = customAttributesArray.value.map(item => {
          let val
          if (item.custom_attribute.data_type.id === 3) val = item.value ? new Date(item.value) : null
          else if (item.custom_attribute.data_type.id === 4) val = item.value?.toString() || null
          else if (item.custom_attribute.data_type.id === 5) val = JSON.stringify(item.value.sort((a, b) => a - b) || [])
          else val = item.value || null

          return {
            id: item.custom_attribute.id,
            value: val,
          }
        })
        console.log(payloadAttr)
        if (!updateMode.value) {
          apolloClient.mutate({
            mutation: addTask,
            variables: {
              assign_user_id: assignUserId.length ? assignUserId : null,
              assign_team_id: assignTeamId.length ? assignTeamId : null,
              subject: data.subject,
              description: data.description,
              start_date: data.startDate ? new Date(Date.parse(data.startDate)) : null,
              end_date: data.endDate ? new Date(Date.parse(data.endDate)) : null,
              customer_id: data.customer ? data.customer.id : null,
              job_id: data.job ? data.job.id : null,
              visibility: data.visibility,
              visibility_5_users: visibilityUsers.length ? visibilityUsers : null,
              tag_id: data.tags,
              workspace_id: store.getters.getCurrentWorkspaceId,
              custom_attribute: payloadAttr,
            },
          }).then(result => {
            loadingSubmit.value = false
            emit('success', result)
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil membuat task!',
            })
            close()
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        } else {
          apolloClient.mutate({
            mutation: updateTask,
            variables: {
              assign_user_id: assignUserId.length ? assignUserId : null,
              assign_team_id: assignTeamId.length ? assignTeamId : null,
              subject: data.subject,
              description: data.description,
              start_date: data.startDate ? new Date(Date.parse(data.startDate)) : null,
              end_date: data.endDate ? new Date(Date.parse(data.endDate)) : null,
              customer_id: data.customer ? data.customer.id : null,
              job_id: data.job ? data.job.id : null,
              visibility: data.visibility,
              visibility_5_users: visibilityUsers.length ? visibilityUsers : null,
              tag_id: data.tags,
              workspace_id: store.getters.getCurrentWorkspaceId,
              task_id: data.id,
            },
          }).then(result => {
            loadingSubmit.value = false
            emit('success', result)
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah task!',
            })
            close()
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        }
      }
    }

    return {
      isOpen,
      updateMode,
      taskForm,
      loadingSubmit,
      loadingDetail,
      formData,
      visibilityPicker,

      required,
      validateDeadline,

      update,
      show,
      reset,
      close,
      submitForm,

      icons: {
        mdiClose,
        mdiPlus,
      },

      customAttributesArray,
      fetchCustomAttributes,
      formCustomAttribute,
    }
  },
}
</script>

<style>

</style>
