<template>
  <v-autocomplete
    ref="tagInput"
    v-model="inputData"
    placeholder="Tag Task"
    :label="label"
    outlined
    dense
    :loading="loadingAll"
    :items="types"
    item-text="name"
    item-value="id"
    multiple
    chips
    deletable-chips
    small-chips
    hide-details="auto"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
    @keydown="onKeyDown"
  >
    <template #no-data>
      <div
        v-if="searchQuery && !readOnly && $can('create', 'Tag')"
        class="d-flex justify-space-between px-4 py-2 cursor-pointer"
        @click="checkAddTag"
      >
        <span class="text-subtitle-2">Tambahkan <v-chip small>{{ searchQuery }}</v-chip> dan pilih.</span>
        <v-icon size="20">
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
      <div
        v-else
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        Tag tidak ditemukan. {{ readOnly || !$can('create', 'Tag') ? '' : 'Ketik sesuatu dan buat tag baru.' }}
      </div>
    </template>
    <template
      v-if="types.length && $can('create', 'Tag')"
      #append-item
    >
      <div
        v-if="searchQuery && !isEmpty && !readOnly"
        class="d-flex justify-space-between px-4 py-2 cursor-pointer"
        @click="checkAddTag"
      >
        <span class="text-subtitle-2">Tambahkan <v-chip small>{{ searchQuery }}</v-chip> dan pilih.</span>
        <v-icon size="20">
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
    </template>
    <template #item="{ item }">
      <v-hover #default="{ hover: isHovered }">
        <div class="d-flex w-full justify-space-between py-2">
          <v-chip
            small
            :color="item.color"
          >
            {{ item.name }}
          </v-chip>
          <v-menu
            v-if="!readOnly"
            v-model="vMenuModel[item.id]"
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-slide-x-reverse-transition mode="out-in">
                <v-icon
                  v-show="isHovered"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDotsHorizontal }}
                </v-icon>
              </v-slide-x-reverse-transition>
            </template>

            <v-card
              class="pa-3"
            >
              <v-text-field
                v-model="item.name"
                dense
                :disabled="!$can('update', 'Tag')"
                placeholder="Update Tags"
                hide-details
                outlined
                :append-icon="item.name ? icons.mdiCheck : null"
                @keydown="$event.keyCode === 13 ? changeTag(item) : null"
                @blur="getTags()"
                @click:append="changeTag(item)"
              />
              <color-picker
                v-if="$can('update', 'Tag')"
                v-model="item.color"
                class="mt-6 mb-4"
                @input="$event ? changeTag(item, $event) : null"
              />
              <v-btn
                v-if="$can('del', 'Tag')"
                color="error"
                block
                small
                outlined
                class="mt-2"
                @click="confirmDeleteTag(item)"
              >
                Hapus Tag
              </v-btn>
            </v-card>
          </v-menu>
        </div>
      </v-hover>
    </template>
    <template #selection="{ item }">
      <v-chip
        small
        :color="item.color"
        class="mt-2"
      >
        {{ item.name }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from 'vue'
import { ref, computed, onMounted } from '@vue/composition-api'
import {
  mdiPlus, mdiDotsHorizontal, mdiCheck, mdiClose,
} from '@mdi/js'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import { randomizeColor } from '@/utils/colorSelection'
import useTag from '@/composables/useTag'

export default {
  components: {
    ColorPicker,
  },
  props: {
    value: {
      type: Array,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: 'Tag Task',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const types = ref([])
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const tagInput = ref(null)
    const vMenuModel = ref({})

    const {
      fetchTags, loadingTags, tagList,
      checkDeleteTag, loadingCheckDeleteTag, checkDeleteTagData,
      deleteTag, loadingDeleteTag,
      addTag, loadingAddTag,
      updateTag, loadingUpdateTag,
    } = useTag()

    const loadingAll = computed(() => loadingTags.value || loadingCheckDeleteTag.value || loadingDeleteTag.value || loadingAddTag.value || loadingUpdateTag.value)

    const searchQuery = ref('')
    const onSearch = data => {
      searchQuery.value = data
    }

    const getTags = async () => {
      fetchTags(searchQuery.value).then(() => {
        types.value = tagList.value
        tagList.value.forEach(el => {
          vMenuModel.value[el.id] = false
        })
      })
    }

    onMounted(() => {
      getTags()
    })

    const checkAddTag = () => {
      const filterTag = types.value.find(el => el.name === searchQuery.value)
      if (filterTag) {
        if (!inputData.value.some(el => el === filterTag.id)) {
          emit('input', [
            ...inputData.value,
            filterTag.id,
          ])
        }
        tagInput.value.lazySearch = ''
      } else {
        addTag({
          name: searchQuery.value,
          color: randomizeColor().hex,
        }).then(result => {
          searchQuery.value = ''
          tagInput.value.lazySearch = ''
          getTags().then(() => {
            emit('input', [
              ...inputData.value,
              JSON.parse(result.data).id,
            ])
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menambah tag!',
            })
          })
        })
      }
    }

    const onKeyDown = data => {
      if (data.keyCode === 13 && searchQuery.value) {
        checkAddTag()
      }
    }

    const isEmpty = ref(false)
    const onIndex = () => {
      isEmpty.value = tagInput.value.filteredItems.length === 0
    }

    const changeTag = item => {
      updateTag(item).then(() => {
        vMenuModel.value[item.id] = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah tag!',
        })
        getTags()
      })
    }

    const confirmDeleteTag = item => {
      checkDeleteTag(item.id).then(() => {
        Vue.$dialog({
          title: 'Hapus tag ini?',
          body: `Anda yakin ingin menghapus tag ini? \n\nada ${checkDeleteTagData.value.job_count} job dan ${checkDeleteTagData.value.task_count} task yang menggunakan tag ini}`,
        }).then(confirm => {
          if (confirm) {
            deleteTag(item.id).then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus tag!',
              })
              getTags()
              tagInput.value.focus()
              tagInput.value.activateMenu()
            })
          }
        })
      })
    }

    return {
      inputData,
      types,
      vMenuModel,
      loadingAll,
      tagInput,
      searchQuery,
      onSearch,
      checkAddTag,
      onKeyDown,
      confirmDeleteTag,
      changeTag,
      getTags,
      isEmpty,
      onIndex,

      icons: {
        mdiPlus,
        mdiDotsHorizontal,
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>

<style>
.v-list-item {
  width: 100% !important;
}
</style>
