import gql from 'graphql-tag'

const chatRoomNotif = gql`
  subscription($workspace_id: Float!) {
    chatRoomNotif(workspace_id: $workspace_id) {
      room {
        id
        third_party_room_id
        status {
          id
          name
        }
        agent {
          id
          first_name
          last_name
          photo
        }
        profile {
          id
          user_id
          name
          photo
          type
          customer {
            id
            name
            description
          }
        }
        lastChat {
          id
          message_id
          content
          type
          sender {
            id
            name
          }
          reply_to
          file_id
          file_url
          caption
          additional_data
          file_name
          file_size
          read
          created_at
          updated_at
        }
        workspaceToken {
          id
          connection_name
          platform {
            id
            name
            logo
          }
        }
        unreadMsg
      }
    }
  }
`

export default chatRoomNotif
