<template>
  <v-card
    :outlined="!expand || outlined"
    class="comment"
  >
    <div class="d-flex pa-2 align-center">
      <div class="d-flex flex-column ml-1">
        <slot name="header">
          <span class="text-subtitle-1 font-medium primary--text"> Komentar </span>
          <span class="text-caption font-medium text--disabled">
            {{ commentTitle() }}
          </span>
        </slot>
      </div>
      <v-btn
        icon
        class="ml-auto mr-1"
        @click="showSearch = !showSearch"
      >
        <v-icon>
          {{ icons.mdiCommentSearchOutline }}
        </v-icon>
      </v-btn>
      <v-menu
        offset-y
        bottom
        left
        min-width="220px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            size="20"
            class="mr-1"
            v-bind="attrs"
            v-on="on"
          >
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            class="d-flex w-full"
            @click="fetchComments()"
          >
            <span class="text-subtitle-2">Muat Ulang</span>
            <v-icon class="ml-auto">
              {{ icons.mdiRefresh }}
            </v-icon>
          </v-list-item>
          <v-divider class="my-2" />
          <v-list-item
            class="d-flex w-full"
            @click="changeTabs"
          >
            <span class="text-subtitle-2">
              {{ currentTabComment === 0 ? 'Foto dan Dokumen' : 'Daftar Komentar' }}
            </span>
            <v-icon
              v-if="currentTabComment === 0"
              class="ml-auto"
            >
              {{ icons.mdiFileDocument }}
            </v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="!expand"
        icon
        class="ml-1"
        @click="$emit('close')"
      >
        <v-icon>
          {{ icons.mdiChevronDown }}
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="showSearch"
      class="d-flex align-center mx-2"
    >
      <div
        v-if="!isSearching"
        class="d-flex align-center justify-space-between flex-grow-1"
      >
        <v-text-field v-model="searchTextQuery" />
      </div>
      <div
        v-else
        class="d-flex align-center justify-space-between flex-grow-1"
      >
        <div>
          Mencari keyword : <b>{{ searchTextQuery }}</b>
        </div>
        <div v-if="!currentTabComment">
          <b>{{ indexHighlight + 1 }}</b> dari <b>{{ matchesCount }}</b>
        </div>
      </div>
      <div
        v-if="!isSearching"
        class="d-flex align-center"
      >
        <v-btn
          icon
          class="ml-auto mr-1"
          @click="searchKeyword"
        >
          <v-icon>
            {{ icons.mdiMagnify }}
          </v-icon>
        </v-btn>
      </div>
      <div
        v-else
        class="d-flex align-center"
      >
        <v-btn
          icon
          class="ml-auto mr-1"
          @click="resetKeyword"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
        <div
          v-if="!currentTabComment"
          class="d-flex align-center"
        >
          <v-btn
            class="flex-grow-1"
            icon
            :disabled="indexHighlight === 0"
            @click="searchUp"
          >
            <v-icon>
              {{ icons.mdiChevronUp }}
            </v-icon>
          </v-btn>
          <v-btn
            class="flex-grow-1"
            icon
            :disabled="indexHighlight === matchesCount - 1"
            @click="searchDown"
          >
            <v-icon>
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-progress-linear
      v-if="loadingComments"
      indeterminate
      color="primary"
      height="1"
    />
    <v-divider v-else />
    <v-tabs-items
      v-model="currentTabComment"
      touchless
    >
      <v-tab-item>
        <div
          v-if="commentList.length"
          id="commentList"
          ref="commentListRef"
          v-scroll:#commentList="listenScrollFetchMoreComment"
          class="list px-2 pt-2"
          :style="`height: ${componentHeight}`"
        >
          <div class="scroll-down">
            <v-fade-transition mode="out-in">
              <v-btn
                v-show="scrollTop !== 0 && scrollTop < scrollHeight - 600"
                fab
                x-small
                color="primary"
                class="ml-auto mb-4 mr-4"
                @click="initializeScroll"
              >
                <v-icon>
                  {{ icons.mdiChevronDown }}
                </v-icon>
              </v-btn>
            </v-fade-transition>
          </div>
          <div
            v-for="(list, i) in commentList"
            :id="`comment-${list.id}`"
            :key="i"
            :class="`comments`"
          >
            <v-hover #default="{ hover: isHovered }">
              <div class="d-flex mb-2 box-comment">
                <v-avatar
                  size="28"
                  class="mr-2 mt-1 v-avatar-light-bg"
                >
                  <v-img
                    v-if="list.photo"
                    :src="list.photo"
                  />
                  <span
                    v-else
                    class="text-caption font-medium"
                  >
                    {{ avatarText(`${list.user.first_name} ${list.user.last_name}`) }}
                  </span>
                </v-avatar>
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <span
                      class="text-caption font-medium primary--text mr-2"
                    >{{ list.user.first_name }} {{ list.user.last_name }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          class="text-caption text--disabled"
                          v-on="on"
                        >
                          {{ dateFormat(list.created_at, 8) }}
                        </span>
                      </template>
                      <div class="d-flex flex-column">
                        <span>Dikirim: {{ dateFormat(list.created_at, 3) }}</span>
                        <span v-if="list.updated_at">Diperbarui: {{ dateFormat(list.updated_at, 3) }}</span>
                      </div>
                    </v-tooltip>
                  </div>
                  <comment :comment="list.comment" />
                  <limit-elements
                    v-if="list.file && list.file.length"
                    class="mt-1"
                    :elements="list.file"
                    :limit="3"
                  >
                    <template #default="{ data }">
                      <comment-file
                        :data="data"
                        @preview="$refs.docViewer.show(data.fileType, data.file_path)"
                      />
                    </template>
                    <template #others="{ data }">
                      <comment-file
                        class="mb-2"
                        :data="data"
                        @preview="$refs.docViewer.show(data.fileType, data.file_path)"
                      />
                    </template>
                    <template #others-activator="{ data }">
                      <v-card
                        outlined
                        class="file d-flex align-center justify-center"
                      >
                        <span class="text-caption text--disabled"> + {{ data.limit }} lainnya </span>
                      </v-card>
                    </template>
                  </limit-elements>
                </div>
                <v-slide-x-reverse-transition mode="out-in">
                  <div
                    v-show="isHovered"
                    class="comment-action"
                  >
                    <v-menu
                      v-if="list.user.id === $store.getters.getUserData.id"
                      :close-on-content-click="false"
                      offset-y
                      nudge-bottom="8px"
                      transition="slide-x-reverse-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          x-small
                          outlined
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Edit
                        </v-btn>
                      </template>
                      <v-card
                        class="comment"
                        width="500px"
                      >
                        <comment-input
                          :job-id="jobId"
                          :prospect-id="prospectId"
                          :task-id="taskId"
                          update-mode
                          :content="list"
                          @updated="
                            fetchComments()
                            $emit('updateComment', $event)
                          "
                        />
                      </v-card>
                    </v-menu>
                    <v-btn
                      v-if="list.user.id === $store.getters.getUserData.id"
                      color="error"
                      x-small
                      outlined
                      @click="deleteComment(list.id)"
                    >
                      Hapus
                    </v-btn>
                  </div>
                </v-slide-x-reverse-transition>
              </div>
            </v-hover>
          </div>
        </div>
        <div
          v-else
          class="list d-flex align-center justify-center"
          :style="`height: ${componentHeight}`"
        >
          <span class="text-subtitle-1 text--disabled"> Belum ada komentar </span>
        </div>
        <v-divider />
        <div v-if="$can('create', 'Comment')">
          <comment-input
            v-if="currentTabComment === 0"
            :toggleable="toggleable"
            :job-id="jobId"
            :prospect-id="prospectId"
            :task-id="taskId"
            @submit="initializeScroll"
            @open="
              isInputEnable = true
              initializeScroll()
            "
            @close="isInputEnable = false"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div
          class="list"
          :style="`height: ${componentHeight}`"
        >
          <CommentFileList
            v-if="currentTabComment === 1"
            :loading-files="loadingFiles"
            :comments-file="commentFileList"
            @fetch-comment-files="fetchCommentFiles"
            @close="changeTabs"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>

    <document-viewer ref="docViewer" />
  </v-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  mdiRefresh,
  mdiChevronUp,
  mdiChevronDown,
  mdiDotsVertical,
  mdiFileDocument,
  mdiCommentSearchOutline,
  mdiMagnify,
  mdiClose,
} from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { onMounted, computed, ref } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'
import useComment from '@/composables/useComment'
import dateFormat from '@/utils/dateFormat'
import { commentSubscription } from '@/graphql/subscriptions'
import Comment from './Comment.vue'
import CommentFile from './CommentFile.vue'
import CommentFileList from './CommentFileList.vue'
import CommentInput from './CommentInput.vue'
import DocumentViewer from '@/components/misc/DocumentViewer.vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    Comment,
    CommentFile,
    CommentFileList,
    CommentInput,
    DocumentViewer,
    LimitElements,
  },
  props: {
    jobId: {
      type: Number,
      default: null,
    },
    prospectId: {
      type: Number,
      default: null,
    },
    taskId: {
      type: Number,
      default: null,
    },
    expand: {
      type: Boolean,
      default: null,
    },
    expandHeight: {
      type: [String, Number],
      default: '60',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    isSubscribed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const searchQuery = ref('')
    const searchFileQuery = ref('')
    const totalMatches = ref(0)
    const indexHighlight = ref(0)

    const {
      commentListRef,
      loadingComments,
      loadingFiles,
      commentList,
      commentFileList,
      matchesCount,
      isInputEnable,
      currentTabComment,
      showSearch,
      isSearching,
      scrollTop,
      scrollHeight,
      calculateScrollHeight,
      initializeScroll,
      listenScrollFetchMoreComment,
      fetchComments,
      fetchCommentsMore,
      fetchCommentFiles,
      searchComment,
      resetComments,
      deleteComment,
    } = useComment({
      jobId: props.jobId,
      taskId: props.taskId,
      search: searchQuery,
      searchFile: searchFileQuery,
    })

    const searchTextQuery = computed({
      get() {
        if (currentTabComment.value === 1) {
          return searchFileQuery.value
        }

        return searchQuery.value
      },
      set(val) {
        if (currentTabComment.value === 1) {
          searchFileQuery.value = val
        } else {
          searchQuery.value = val
        }
      },
    })

    const isSubscribedComputed = computed({
      get() {
        return props.isSubscribed
      },
      set(val) {
        emit('changeSubscription', val)
      },
    })

    const componentHeight = computed(() => {
      const { height } = useWindowSize()

      if (!props.expand) return '40vh'

      if (props.toggleable && !isInputEnable.value) {
        return `${(+props.expandHeight / 100) * height.value + 97.5}px`
      }

      return `${(+props.expandHeight / 100) * height.value}px`
    })

    onMounted(async () => {
      await fetchComments()

      setTimeout(() => {
        initializeScroll()
      }, 300)
    })

    const observer = apolloClient.subscribe({
      query: commentSubscription,
      fetchPolicy: 'no-cache',
      variables: {
        job_id: props.jobId,
        task_id: props.taskId,
      },
    })

    observer.subscribe({
      next(sub) {
        emit('receiveComment', sub)
        const data = sub.data.commentSubscription
        console.log('receiveComment', sub.data.commentSubscription)

        if (data.actionType === 'insert') {
          commentList.value.push({
            id: data.id,
            user: data.user,
            comment: data.comment,
            created_at: data.created_at,
            updated_at: null,
            file: data.file,
          })

          commentList.value = commentList.value.sort(
            (a, b) => new Date(Date.parse(a.created_at)) - new Date(Date.parse(b.created_at)),
          )
          calculateScrollHeight()
        }

        if (data.actionType === 'delete') {
          commentList.value = commentList.value.filter(el => el.id !== data.id)
        }

        if (data.actionType === 'update') {
          commentList.value = commentList.value.map(el => {
            if (el.id === data.id) {
              return {
                ...el,
                comment: data.comment,
              }
            }

            return el
          })
        }
      },
      error(error) {
        console.error(error)
        errorHandling(error)
      },
    })

    const commentTitle = () => {
      if (props.jobId) return `Job #${props.jobId}`
      if (props.prospectId) return `Prospek #${props.prospectId}`
      if (props.taskId) return `Task #${props.taskId}`

      return ''
    }

    const resetHighlight = () => {
      const listMatches = document.getElementsByClassName('match')

      for (let i = 0; i < listMatches.length; i += 1) {
        listMatches[i].style.backgroundColor = ''
      }
    }

    const hightlightText = async () => {
      resetHighlight()

      const el = commentListRef.value

      const indexNow = indexHighlight.value
      const elMatched = document.querySelectorAll(`[data-matched='${indexNow}']`)
      if (elMatched.length) {
        elMatched[0].style.backgroundColor = 'yellow'

        const parentTarget = elMatched[0].closest('.comments')
        const toTopLocation = parentTarget.offsetTop - parentTarget.scrollHeight

        el.scrollTo({ top: toTopLocation, behavior: 'smooth' })
      } else {
        const data = await fetchCommentsMore()
        if (data.length) {
          hightlightText()
        }
      }
    }

    const searchKeyword = async () => {
      isSearching.value = true
      if (currentTabComment.value === 1) {
        await fetchCommentFiles()
      } else {
        await searchComment()

        indexHighlight.value = matchesCount.value - 1

        hightlightText()
      }
    }

    const searchUp = async () => {
      const indexNow = indexHighlight.value
      if (indexNow - 1 >= 0) {
        indexHighlight.value -= 1

        hightlightText()
      }
    }

    const searchDown = () => {
      const indexNow = indexHighlight.value
      if (indexNow + 1 < matchesCount.value) {
        indexHighlight.value += 1

        hightlightText()
      }
    }

    const resetKeyword = async () => {
      isSearching.value = false

      if (!currentTabComment.value) {
        searchQuery.value = ''
        indexHighlight.value = 0

        resetComments()
      } else {
        searchFileQuery.value = ''

        await fetchCommentFiles()
      }
    }

    const changeTabs = () => {
      resetKeyword()

      currentTabComment.value = currentTabComment.value === 1 ? 0 : 1
    }

    return {
      searchTextQuery,
      totalMatches,
      matchesCount,
      indexHighlight,
      commentListRef,
      loadingComments,
      loadingFiles,
      commentList,
      commentFileList,
      currentTabComment,
      showSearch,
      isSearching,
      scrollTop,
      scrollHeight,

      initializeScroll,
      fetchComments,
      listenScrollFetchMoreComment,
      fetchCommentFiles,
      deleteComment,

      isInputEnable,
      isSubscribedComputed,
      componentHeight,
      avatarText,
      dateFormat,
      commentTitle,
      searchKeyword,
      searchUp,
      searchDown,
      resetKeyword,
      changeTabs,

      icons: {
        mdiRefresh,
        mdiChevronUp,
        mdiChevronDown,
        mdiClose,
        mdiDotsVertical,
        mdiFileDocument,
        mdiCommentSearchOutline,
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import './style.scss';

.scroll-down {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 143px;
  z-index: 99;
}
</style>

<style lang="scss" scoped>
.file {
  height: 80px;
  width: 120px;
}
</style>
