<template>
  <v-card
    class="pa-3 d-flex flex-column"
  >
    <!-- <div class="d-flex justify-space-between mb-3">
      <span class="text-subtitle-1 font-medium">Pelanggan</span>
      <v-btn
        color="primary"
        x-small
      >
        Ubah
      </v-btn>
    </div> -->
    <div class="d-flex align-center mb-3">
      <v-avatar
        color="primary"
        class="v-avatar-light-bg primary--text mr-2"
        size="44"
      >
        {{ detail.customer && avatarText(detail.customer.name) }}
      </v-avatar>
      <div class="d-flex flex-column">
        <span class="text-subtitle-2 primary--text font-medium">{{ detail.customer && detail.customer.name }}</span>
        <span class="text-caption text--disabled">{{ detail.customer && detail.customer.phone_number }}</span>
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row">
      <v-card
        outlined
        class="pa-2 d-flex align-center w-full mr-3 mb-3 mb-sm-0"
      >
        <v-avatar
          class="v-avatar-light-bg primary--text mr-2"
          rounded
          size="44"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiChartAreaspline }}
          </v-icon>
        </v-avatar>
      </v-card>
      <v-card
        outlined
        class="pa-2 d-flex align-center w-full"
      >
        <v-avatar
          class="v-avatar-light-bg primary--text mr-2"
          rounded
          size="44"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiChartAreaspline }}
          </v-icon>
        </v-avatar>
      </v-card>
    </div>
  </v-card>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  mdiDotsVertical, mdiChartAreasplineVariant, mdiChartAreaspline, mdiEmailOutline, mdiPhoneOutline, mdiWhatsapp,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'

export default defineComponent({
  name: 'CustomerProspect',
  props: {
    detail: {
      type: Object,
      default: {},
    },
  },
  setup() {
    return {
      avatarText,
      icons: {
        mdiDotsVertical,
        mdiChartAreasplineVariant,
        mdiChartAreaspline,
        mdiEmailOutline,
        mdiPhoneOutline,
        mdiWhatsapp,
      },
    }
  },
})
</script>
<style lang="scss">
.ps-customer {
  max-height: 80vh;
}
</style>
