<template>
  <v-dialog
    v-model="isOpen"
    max-width="1200"
    scrollable
  >
    <v-card
      v-if="taskDetail.subject"
      :loading="loadingTaskDetail"
    >
      <div class="d-flex align-start pa-4">
        <span class="text-subtitle-1 font-medium primary--text">
          {{ taskDetail.subject }}
        </span>
        <v-icon
          class="ml-auto mt-1"
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-divider />
      <v-card-text class="pt-2 px-0">
        <div class="px-4">
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <div class="d-flex flex-column">
                <span
                  v-if="taskDetail.start_date"
                  class="text-caption text--disabled"
                >Waktu Mulai</span>
                <span
                  v-if="taskDetail.start_date"
                  class="text-caption"
                >{{ dateFormat(taskDetail.start_date) }}</span>
                <span class="text-caption text--disabled mt-1">Waktu Selesai (Deadline)</span>
                <span class="text-caption">{{ dateFormat(taskDetail.end_date) }}</span>
                <span class="text-caption text--disabled mt-1">Deskripsi Task</span>
                <span class="description">{{ taskDetail.description }}</span>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="d-flex flex-column align-md-end ml-auto mt-n4 mt-md-0"
            >
              <div
                class="d-flex flex-wrap justify-md-end align-start mt-md-1"
              >
                <v-chip
                  v-for="tag in taskDetail.tag"
                  :key="tag.id"
                  x-small
                  :color="tag.color"
                  class="mr-1 mr-md-0 ml-md-1 mb-1"
                >
                  {{ tag.name }}
                </v-chip>
              </div>
              <span class="text-caption text--disabled mt-2">Ditugaskan ke</span>
              <LimitElements
                v-if="taskDetail.assignedTo.length"
                :elements="taskDetail.assignedTo"
                :limit="6"
                class="v-avatar-group"
                :class="rootThemeClasses"
              >
                <template #default="{ data }">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        class="v-avatar-light-bg"
                        size="36"
                        v-on="on"
                      >
                        <v-img
                          v-if="data.user.photo"
                          :src="data.user.photo"
                        />
                        <span v-else>{{ avatarText(data.user.name) }}</span>
                      </v-avatar>
                    </template>
                    <span>{{ data.user.name }}</span>
                  </v-tooltip>
                </template>
                <template #others="{ data }">
                  <div class="d-flex align-center mb-1">
                    <v-avatar
                      class="v-avatar-light-bg mr-2"
                      size="20"
                    >
                      <v-img
                        v-if="data.user.photo"
                        :src="data.user.photo"
                      />
                      <span
                        v-else
                        class="text-avatar-small"
                      >{{ avatarText(data.user.name) }}</span>
                    </v-avatar>
                    <span class="text-subtitle-2">{{ data.user.name }}</span>
                  </div>
                </template>
                <template #others-activator="{ data }">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        small
                        v-bind="attrs"
                        size="36"
                        color="white primary--text text-caption"
                        v-on="on"
                      >
                        <span>+{{ data.limit }}</span>
                      </v-avatar>
                    </template>
                    <span>Lihat User Lain</span>
                  </v-tooltip>
                </template>
              </LimitElements>
            </v-col>
          </v-row>
        </div>
        <PerfectScrollbar
          :options="{
            useBothWheelAxes:true
          }"
          class="horizontal-list pa-4"
        >
          <task-info
            v-if="taskDetail.customer"
            title="Pelanggan"
            :to="{ name: 'customer-detail', params: { id: taskDetail.customer.id } }"
          >
            {{ taskDetail.customer.name }}
          </task-info>
          <task-info
            v-if="taskDetail.job"
            title="Job"
            :to="{ name: 'job-detail', params: { id: taskDetail.job.id } }"
          >
            {{ taskDetail.job.name }}
          </task-info>
          <task-info
            v-if="taskDetail.prospect"
            title="Prospek"
            :to="{ name: 'prospect-detail', params: { id: taskDetail.prospect.id } }"
          >
            {{ taskDetail.prospect.name }}
          </task-info>
          <task-info
            title="Dibuat oleh"
            :to="{ name: 'user-detail', params: { id: taskDetail.created_by.id } }"
          >
            {{ taskDetail.created_by.name }}
          </task-info>
          <v-tooltip
            top
            allow-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <v-card
                flat
                v-bind="attrs"
                v-on="on"
              >
                <task-info title="Visibilitas">
                  {{ taskDetail.visibility.name }}
                </task-info>
              </v-card>
            </template>
            <span class="d-block">Visibilitas: {{ taskDetail.visibility.name }}</span>
            <span>{{ taskDetail.visibility.description }}</span>
          </v-tooltip>
          <task-info title="Waktu Dibuat">
            {{ dateFormat(taskDetail.created_at) }}
          </task-info>
          <task-info
            v-if="taskDetail.updated_at"
            title="Terakhir Diperbarui"
          >
            {{ dateFormat(taskDetail.updated_at) }}
          </task-info>
        </PerfectScrollbar>
        <span class="px-4">
          Atribut Lainnya
        </span>
        <PerfectScrollbar
          :options="{
            useBothWheelAxes:true
          }"
          class="horizontal-list px-4 pt-0 pb-4"
        >
          <task-info
            v-for="attr in customAttributesArray"
            :key="attr.id"
            :title="attr.custom_attribute.name"
            class="mt-2"
          >
            {{ getAttributeValue(attr) }}
          </task-info>
          <v-tooltip
            top
          >
            <template #activator="{on, attrs}">
              <v-card
                class="mt-2 d-flex horizontal-list-button"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  class="align-self-center py-7"
                  elevation="0"
                  @click="$refs.customAttributesList.open()"
                >
                  <v-icon
                    size="26"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card>
            </template>
            <span>Edit / Tambah atribut</span>
          </v-tooltip>
        </PerfectScrollbar>
        <v-divider />
        <div class="px-4">
          <v-row class="mt-2 match-height">
            <v-col
              v-if="$can('read', 'Comment')"
              cols="12"
              md="6"
            >
              <comment-box
                outlined
                expand
                :task-id="taskDetail.id"
              />
            </v-col>
            <v-col
              cols="12"
              :md="$can('read', 'Comment') ? 6 : 12"
            >
              <v-card
                outlined
                class="d-flex align-center justify-center"
              >
                Timeline Goes Here
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="$can('update', 'Task')"
          outlined
          small
          color="warning"
          @click="$refs.taskForm.update(taskDetail)"
        >
          Edit
        </v-btn>
        <v-btn
          v-if="$can('del', 'Task')"
          outlined
          small
          color="error"
          @click="del"
        >
          Hapus
        </v-btn>
        <v-spacer />
        <div
          v-if="!dateInPast(taskDetail.end_date) && !taskDetail.completed_at"
          class="mr-4 d-none d-sm-flex flex-column align-end"
        >
          <span class="text-caption text--disabled">
            Sisa Waktu
          </span>
          <CountdownTimer
            v-if="isOpen"
            :date="taskDetail && taskDetail.end_date"
            custom-class="text-caption font-medium primary--text mt-n1"
          />
        </div>
        <v-btn
          outlined
          small
          text
          :color="taskDetail.completed_at ? 'secondary' : 'primary'"
          @click="check"
        >
          {{ taskDetail.completed_at ? 'Sudah Selesai' : 'Selesaikan' }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <task-form
      ref="taskForm"
    />

    <custom-attributes-list
      ref="customAttributesList"
      :object-id="taskId"
      :object-type-id="1"
      @update="fetchCustomAttributes(taskId)"
    />
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { mdiClose, mdiPlus } from '@mdi/js'
import useVuetify from '@core/utils/vuetify'
import { avatarText } from '@core/utils/filter'
import { dateInPast } from '@core/utils'
import CommentBox from '@/components/misc/CommentBox.vue'
import dateFormat from '@/utils/dateFormat'
import CountdownTimer from '@/components/misc/CountdownTimer.vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import TaskInfo from './components/TaskDetailInfo.vue'
import useTask from '@/composables/useTask'
import useCustomAttribute from '@/composables/useCustomAttributes'
import TaskForm from './TaskForm.vue'
import CustomAttributesList from '../../custom-attributes/CustomAttributesList.vue'

export default {
  components: {
    CommentBox,
    PerfectScrollbar,
    TaskInfo,
    CountdownTimer,
    LimitElements,
    TaskForm,
    CustomAttributesList,
  },
  setup(prop, { emit }) {
    const taskId = ref(null)
    const isOpen = ref(false)
    const { rootThemeClasses } = useVuetify()
    const {
      taskDetail, loadingTaskDetail, getTaskDetail,
      deleteTask, checkTask,
    } = useTask()

    const {
      fetchCustomAttributes, loadingFetch, customAttributesArray, getAttributeValue,
    } = useCustomAttribute({ objectTypeId: 1 })

    const customAttributesList = ref(null)

    const show = async id => {
      isOpen.value = true
      taskId.value = id
      await getTaskDetail(id)
      fetchCustomAttributes(taskId.value)
    }

    const close = () => {
      isOpen.value = false
    }

    const check = () => {
      checkTask(taskDetail.value.id, taskDetail.value.completed_at != null).then(() => {
        close()
        emit('refetch')
      })
    }

    const del = () => {
      deleteTask(taskDetail.value.id).then(() => {
        close()
        emit('refetch')
      })
    }

    return {
      isOpen,
      rootThemeClasses,
      taskDetail,
      loadingTaskDetail,
      loadingFetch,

      show,
      close,
      dateFormat,
      avatarText,
      dateInPast,
      deleteTask,
      check,
      del,

      customAttributesList,
      customAttributesArray,
      fetchCustomAttributes,
      taskId,
      getAttributeValue,

      icons: {
        mdiClose,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
.horizontal-list {
  display: flex;
  overflow-x: auto;

  .horizontal-list-card {
    flex-shrink: 0;
    width: 200px;
    margin-right: 8px;

    position: relative;
  }

  .horizontal-list-button {
    flex-shrink: 0;
    margin-right: 8px;

    position: relative;
  }
}
</style>
