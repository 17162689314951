<template>
  <v-autocomplete
    ref="productAutoSuggest"
    v-model="inputData"
    placeholder="Cari produk..."
    label="Produk"
    outlined
    dense
    :loading="loading"
    :items="productList"
    :multiple="multiple"
    item-text="name"
    item-value="id"
    chips
    deletable-chips
    :small-chips="multiple"
    hide-details="auto"
    no-filter
    :rules="inputRules"
    :return-object="emitMode"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Produk tidak ditemukan.
        </p>
        <p
          class="mb-0 text-decoration-underline primary--text"
          @click="$refs.productForm.show(searchQuery)"
        >
          Tambah {{ searchQuery }} ke data produk
        </p>
        <ProductForm ref="productForm" />
      </div>
    </template>
    <template #item="data">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-img
            v-if="(data.item.photo.length > 0)"
            :src="data.item.photo[0].photo "
          />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption">
          {{ data.item.code_sku ? data.item.code_sku : "Tidak ada SKU" }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="caption font-weight-semibold">
          {{ data.item.price }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="caption font-weight-semibold">
          Stok {{ data.item.stock }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { minArrayLength, maxArrayLength } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import { products } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import ProductForm from '@/views/product/ProductForm.vue'
import store from '@/store'

export default {
  components: {
    ProductForm,
  },
  props: {
    value: {
      type: [Array, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emitMode: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const log = data => {
      console.log(data)
    }
    const productAutoSuggest = ref(null)
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const inputRules = computed(() => {
      if (props.multiple && (props.min || props.max)) {
        const multipleRules = []

        if (props.max) multipleRules.push(maxArrayLength(inputData.value, props.max))
        if (props.min) multipleRules.push(minArrayLength(inputData.value, props.min))

        return [...props.rules, ...multipleRules]
      }

      return props.rules
    })
    const loading = ref(false)
    const searchQuery = ref('')
    const productList = ref([])
    const fetchProducts = () => {
      loading.value = true
      apolloClient.query({
        query: products,
        variables: {
          pagination: {
            limit: 16,
            offset: 0,
          },
          search: searchQuery.value,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        productList.value = result.data.products.products
        loading.value = false
      }).catch(err => {
        errorHandling(err)
        loading.value = false
      })
    }

    onMounted(() => {
      fetchProducts()
    })

    const onSearch = useDebounceFn(data => {
      searchQuery.value = data
      fetchProducts()
    }, 1000)

    const isEmpty = ref(false)
    const onIndex = () => {
      isEmpty.value = productAutoSuggest.value.filteredItems.length === 0
    }
    watch(inputData, () => {
      emit('data', inputData)
    })

    return {
      productAutoSuggest,
      inputData,
      loading,
      productList,
      searchQuery,
      onSearch,
      isEmpty,
      onIndex,
      log,
      inputRules,
    }
  },
}
</script>

<style>

</style>
