<template>
  <v-card
    outlined
    :to="to"
    class="pa-2 horizontal-list-card"
  >
    <div class="d-flex flex-column">
      <div class="d-flex align-center">
        <span class="text-caption text--disabled">
          {{ title }}
        </span>
        <v-icon
          v-if="to !== null"
          color="primary"
          size="16"
          class="ml-auto"
        >
          {{ icons.mdiOpenInNew }}
        </v-icon>
      </div>
      <span
        class="text-caption font-medium text-truncate"
      >
        <slot />
      </span>
    </div>
  </v-card>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style>

</style>
