<template>
  <v-card
    width="240px"
    class="pa-2"
  >
    <UserTeamAutoSuggest
      v-model="formData.assignTo"
      label="Ditugaskan ke"
      show-teams
      multiple
      :visibility-id="formData.visibilityId"
    />
    <v-btn
      class="mt-2"
      color="primary"
      rounded
      block
      :loading="loadingSubmit"
      small
      @click="submitForm"
    >
      Simpan
    </v-btn>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { assignObject } from '@/graphql/mutations'
import UserTeamAutoSuggest from '@/components/inputs/UserTeamAutoSuggest.vue'

export default {
  name: 'AssignObject',
  components: {
    UserTeamAutoSuggest,
  },
  props: {
    prospectId: {
      type: Number,
      default: null,
    },
    jobId: {
      type: Number,
      default: null,
    },
    data: {
      type: Array,
      default: null,
    },
    visibilityId: {
      type: Number,
      default: 6,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const formData = ref({
      assignTo: [],
      visibilityId: props.visibilityId,
    })
    const loadingSubmit = ref(false)

    const initForm = () => {
      formData.value.assignTo = [...props.data.map(u => `user-${u.user.id}`)]

      console.log(formData.value)
    }

    onMounted(() => {
      initForm()
    })

    const submitForm = () => {
      loadingSubmit.value = true
      const teams = []
      const users = []

      console.log(formData.value.assignTo)
      // eslint-disable-next-line no-unused-expressions
      formData.value.assignTo && formData.value.assignTo.length && formData.value.assignTo.forEach(value => {
        let ids = -1
        if (value.id) {
          ids = value.id.split('-')
        } else {
          ids = value.split('-')
        }

        if (ids[0] === 'team') teams.push(+ids[1])
        if (ids[0] === 'user') users.push(+ids[1])
      })
      apolloClient.mutate({
        mutation: assignObject,
        variables: {
          job_id: props.jobId ? +props.jobId : null,
          assign_user_id: users.length ? users : null,
          assign_team_id: teams.length ? teams : null,
        },
      }).then(result => {
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menugaskan objek ke pengguna!',
        })
        console.log(formData.value)
        loadingSubmit.value = false
        emit('success', result)
      }).catch(err => {
        emit('failed', err)
        loadingSubmit.value = false
        errorHandling(err)
      })
    }

    return {
      isOpen,
      formData,
      initForm,
      loadingSubmit,
      submitForm,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
