/* eslint-disable no-nested-ternary */
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import {
  jobs, jobDetail as jobDetailQuery, jobPriority, jobTypes, jobStatus, getJobTypeDetail,
} from '@/graphql/queries'
import {
  archiveJob, archiveJobType, deleteJobType, editJobTypePermission, proceedJobStatus, updateJob,
} from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import unarchiveJobType from '@/graphql/mutation/unarchiveJobType'

const useJob = ({
  pagination, filter, search,
} = {}) => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const jobList = ref([])
  const jobStatusList = ref([])
  const jobTypeList = ref([])
  const jobPriorityList = ref([])
  const jobTypeDetail = ref(null)
  const jobCount = ref(0)
  const jobFilter = ref({
    pagination: {
      limit: 20,
      offset: 0,
    },
    filter: {
      sort: {
        label: 'Tanggal Ditutup Terlama',
        field: 'expected_close_date',
        type: 'ASC',
      },
      search: '',
      is_archive: false,
    },
  })
  const jobSortOptions = ref([
    {
      label: 'Nama Job A-Z',
      field: 'name',
      type: 'ASC',
    },
    {
      label: 'Nama Job Z-A',
      field: 'name',
      type: 'DESC',
    },
    {
      label: 'Tanggal Ditutup Terlama',
      field: 'expected_close_date',
      type: 'ASC',
    },
    {
      label: 'Tanggal Ditutup Terdekat',
      field: 'expected_close_date',
      type: 'DESC',
    },
    {
      label: 'Diupdate Terlama',
      field: 'updated_at',
      type: 'ASC',
    },
    {
      label: 'Diupdate Terbaru',
      field: 'updated_at',
      type: 'DESC',
    },
  ])
  const jobDetail = ref({})
  const loadingJob = ref(false)
  const loadingUpdateJob = ref(false)
  const loadingJobType = ref(false)
  const loadingJobStatus = ref(false)
  const loadingChangeJobStatus = ref(false)
  const loadingFetchMore = ref(false)
  const loadingJobPagination = ref(false)
  const loadingJobTypeDetail = ref(false)
  const loadingJobDetail = ref(false)
  const loadingArchiveJobType = ref(false)
  const loadingDeleteJobType = ref(false)
  const loadingEditJobType = ref(false)
  const loadingJobPriority = ref(false)

  const jobAutoSuggestPagination = ref({
    limit: 20,
    offset: 0,
  })
  const jobAutoSuggestSearch = ref(null)

  const pushJobs = list => {
    const filteredList = jobList.value.filter(el => !list.some(c => c.id === el.id))

    jobList.value = [...filteredList, ...list]
  }

  const fetchJobs = async ({ fetchMore = false } = {}) => {
    loadingJob.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: jobs,
        variables: {
          pagination: pagination && !fetchMore ? pagination.value : jobFilter.value.pagination,
          filter: filter ? filter.value : {
            sort: jobFilter.value.filter.sort.field,
            order: jobFilter.value.filter.sort.type,
            is_archive: jobFilter.value.filter.is_archive,
            search: search ? search.value : (jobFilter.value.filter.search
              ? jobFilter.value.filter.search
              : null),
            customer: jobFilter.value.filter.customer ? jobFilter.value.filter.customer : null,
            job_type: jobFilter.value.filter.job_type ? jobFilter.value.filter.job_type : null,
          },
          workspace_id: workspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingJob.value = false
        jobCount.value = result.data.jobs.count
        console.log('result.data.jobs.jobs', result.data.jobs.jobs)
        if (fetchMore) {
          pushJobs(result.data.jobs.jobs)
        } else {
          jobList.value = result.data.jobs.jobs
        }
        resolve(jobList.value)
      }).catch(err => {
        loadingJob.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const listenScrollFetchMoreJob = useDebounceFn(async data => {
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 400)
      && jobFilter.value.pagination.offset <= jobCount.value
    ) {
      jobFilter.value.pagination.offset += 20
      loadingJobPagination.value = true
      await fetchJobs({ fetchMore: true })
      loadingJobPagination.value = false
    }
  }, 200)

  const fetchJobsAutoSuggest = async ({ fetchMore = false } = {}) => {
    loadingJob.value = true
    await apolloClient.query({
      query: jobs,
      variables: {
        workspace_id: workspaceId,
        pagination: pagination && !fetchMore ? pagination : jobAutoSuggestPagination.value,
        filter: {},
        search: search ? search.value : jobAutoSuggestSearch.value,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingJob.value = false
      jobCount.value = result.data.jobs.count
      if (fetchMore) {
        pushJobs(result.data.jobs.jobs)
      } else {
        jobList.value = result.data.jobs.jobs
      }
    }).catch(err => {
      loadingJob.value = false
      errorHandling(err)
    })
  }

  const listenScrollAutoSuggestFetchMore = useDebounceFn(async () => {
    jobAutoSuggestPagination.value.offset += 20
    loadingFetchMore.value = true
    await fetchJobsAutoSuggest({ fetchMore: true })
    loadingFetchMore.value = false
  }, 750)

  const debouncedFetchJobs = useDebounceFn(() => {
    fetchJobs()
  }, 1000)

  const deleteJob = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus job?',
      body: 'Konfirmasi jika anda ingin menghapus job.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: archiveJob,
          variables: {
            id,
            is_archive: true,
            workspace_id: workspaceId,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus job!',
          })
          resolve(result)
        }).catch(err => {
          reject(err)
          errorHandling(err, 'Hapus Job')
        })
      } else {
        reject()
      }
    })
  })

  const getJobDetail = id => {
    loadingJobDetail.value = true

    apolloClient.query({
      query: jobDetailQuery,
      variables: {
        job_id: id,
        workspace_id: workspaceId,
      },
    }).then(result => {
      jobDetail.value = result.data.jobDetail
      loadingJobDetail.value = false
    }).catch(err => {
      loadingJobDetail.value = false
      errorHandling(err)
    })
  }

  const getJobPriority = jobTypeArr => {
    loadingJobPriority.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: jobPriority,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: jobTypeArr,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        store.state.job.jobPriority = data.jobPriority
        jobPriorityList.value = data.jobPriority
        loadingJobPriority.value = false
        resolve(data)
      }).catch(err => {
        errorHandling(err)
        loadingJobPriority.value = false
        reject(err)
      })
    })
  }

  const getJobTypes = (folderId, filterJobType) => {
    loadingJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: jobTypes,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: folderId,
          filter: filterJobType,
        },
      }).then(result => {
        loadingJobType.value = false
        store.state.job.jobTypes = result.data.jobTypes
        jobTypeList.value = result.data.jobTypes
        resolve(result)
      }).catch(err => {
        loadingJobType.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const getJobStatus = typeId => {
    loadingJobStatus.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: jobStatus,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: typeId,
        },
      }).then(({ data }) => {
        loadingJobStatus.value = false
        store.state.job.jobStatus = data.jobStatus
        jobStatusList.value = data.jobStatus
        resolve(data.jobStatus)
      }).catch(err => {
        loadingJobStatus.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const changeJobStatus = (jobId, statusId) => {
    loadingChangeJobStatus.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: proceedJobStatus,
        variables: {
          id: jobId,
          status_id: statusId,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(({ data }) => {
        loadingChangeJobStatus.value = false
        resolve(data)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status job!',
        })
      }).catch(err => {
        loadingChangeJobStatus.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchJobTypeDetail = jobTypeId => {
    loadingJobTypeDetail.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getJobTypeDetail,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          jobtype_id: jobTypeId,
        },
      }).then(({ data }) => {
        loadingJobTypeDetail.value = false
        jobTypeDetail.value = data.jobTypeDetail
        resolve(data.jobTypeDetail)
      }).catch(err => {
        loadingJobTypeDetail.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const updateArchiveJobType = jobTypeId => {
    loadingArchiveJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: archiveJobType,
        variables: {
          jobtype_id: jobTypeId,
          workspace_id: workspaceId,
        },
      }).then(result => {
        loadingArchiveJobType.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengarsipkan job type!',
        })
        resolve(result)
      }).catch(err => {
        loadingArchiveJobType.value = false
        reject(err)
        errorHandling(err, 'Archive Job Type')
      })
    })
  }

  const updateUnarchiveJobType = jobTypeId => {
    loadingArchiveJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: unarchiveJobType,
        variables: {
          jobtype_id: jobTypeId,
          workspace_id: workspaceId,
        },
      }).then(result => {
        loadingArchiveJobType.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil memulihkan job type!',
        })
        resolve(result)
      }).catch(err => {
        loadingArchiveJobType.value = false
        reject(err)
        errorHandling(err, 'Unarchive Job Type')
      })
    })
  }

  const changeJob = (id, param) => {
    loadingUpdateJob.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: updateJob,
        variables: {
          id,
          workspace_id: workspaceId,
          ...param,
        },
      }).then(result => {
        loadingUpdateJob.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah job!',
        })
        resolve(result)
      }).catch(err => {
        loadingUpdateJob.value = false
        reject(err)
        errorHandling(err, 'Update Job')
      })
    })
  }

  const removeJobType = jobTypeId => {
    loadingDeleteJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: deleteJobType,
        variables: {
          jobtype_id: jobTypeId,
          workspace_id: workspaceId,
        },
      }).then(result => {
        loadingDeleteJobType.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menghapus job type!',
        })
        resolve(result)
      }).catch(err => {
        loadingDeleteJobType.value = false
        reject(err)
        errorHandling(err, 'Delete Job Type')
      })
    })
  }

  const updateJobTypePermission = async ({
    id,
    isPublic,
    userPermissions,
  }) => {
    loadingEditJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: editJobTypePermission,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          jobtype_id: id,
          is_public: isPublic,
          user_permissions: userPermissions,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah job type!',
        })
        loadingEditJobType.value = false
        resolve(data.updateJobTypePermission)
      }).catch(err => {
        loadingEditJobType.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  return {
    jobList,
    jobCount,
    jobFilter,
    jobSortOptions,
    jobDetail,
    loadingJob,
    loadingJobPagination,
    loadingJobDetail,
    jobAutoSuggestPagination,
    jobAutoSuggestSearch,
    fetchJobs,
    listenScrollAutoSuggestFetchMore,
    listenScrollFetchMoreJob,
    debouncedFetchJobs,
    deleteJob,
    getJobDetail,
    fetchJobsAutoSuggest,

    getJobStatus,
    loadingJobStatus,
    jobStatusList,

    getJobTypes,
    loadingJobType,
    jobTypeList,

    fetchJobTypeDetail,
    loadingJobTypeDetail,
    jobTypeDetail,

    removeJobType,
    loadingDeleteJobType,

    updateArchiveJobType,
    loadingArchiveJobType,

    updateUnarchiveJobType,

    updateJobTypePermission,
    loadingEditJobType,

    changeJob,
    loadingUpdateJob,

    getJobPriority,
    loadingJobPriority,
    jobPriorityList,

    changeJobStatus,
    loadingChangeJobStatus,

  }
}

export default useJob
