<template>
  <v-dialog
    v-model="isOpen"
    max-width="600px"
    @input="!$event ? $emit('close') : null"
  >
    <v-card class="pa-4 d-flex flex-column">
      <div class="d-flex justify-space-between mb-2">
        <span class="text-h6 font-medium primary--text">Filter Task</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <small class="mb-1">Tanggal Deadline</small>
      <DateRangePicker
        v-model="state.filter.value.endAt"
        class="mb-4"
      />
      <small class="mb-1">Tanggal Selesai</small>
      <DateRangePicker
        v-model="state.filter.value.completedAt"
        class="mb-4"
      />
      <tag-auto-suggest
        v-model="state.filter.value.tag"
        class="mb-4"
        label="Tag Task"
      />
      <UserAutoSuggest
        v-model="state.filter.value.assignedTo"
        class="mb-4"
        :multiple="true"
        label="Ditugaskan ke"
      />
      <CustomerAutoSuggest
        v-model="state.filter.value.customer"
        :multiple="true"
        label="Pelanggan"
        class="mb-4"
      />
      <v-btn
        outlined
        @click="resetFilter"
      >
        Reset
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import TagAutoSuggest from '@/components/inputs/TagAutoSuggest.vue'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'

const useFieldTask = createFieldMapper({ getter: 'task/getField', setter: 'task/setField' })

export default {
  components: {
    DateRangePicker,
    TagAutoSuggest,
    UserAutoSuggest,
    CustomerAutoSuggest,
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldTask(['filter']),
    }
    const isOpen = ref(false)

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
      emit('close')
    }

    const resetFilter = () => {
      state.filter.value.endAt = { startDate: null, endDate: null }
      state.filter.value.completedAt = { startDate: null, endDate: null }
      state.filter.value.tag = []
      state.filter.value.assignedTo = []
      state.filter.value.customer = []

      console.log(state.filter.value)

      close()
    }

    return {
      state,
      isOpen,

      show,
      close,
      resetFilter,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
