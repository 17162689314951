import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-3 mt-1 cursor-pointer",on:{"click":function($event){return _vm.$emit('detail', _vm.task.id)}}},[_c('div',{staticClass:"d-flex flex-column flex-md-row justify-space-between"},[_c('div',{staticClass:"d-flex align-start flex-grow-0 task-detail-left"},[_c(VBtn,{staticClass:"mt-n2 ml-n1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('check', _vm.task.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.task.completed_at ? _vm.icons.mdiCheckboxMarked : _vm.icons.mdiCheckboxBlankOutline)+" ")])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('h5',{staticClass:"font-medium mb-1 mr-2"},[_vm._v(" "+_vm._s(_vm.task.subject)+" ")]),_c('LimitElements',{attrs:{"elements":_vm.task.tag,"limit":4},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1",attrs:{"x-small":"","color":data.color}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"others-activator",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1 v-chip-light-bg primary--text mb-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" +"+_vm._s(data.limit)+" ")])]}}])})],1),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.task.description)+" ")])])],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-caption text-md-right mt-1 mt-md-0"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.task.end_date)+" ")])]}}])},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Dibuat: "+_vm._s(_vm.task.created_at))]),_c('span',[_vm._v("Diperbarui: "+_vm._s(_vm.task.updated_at))])])])],1),_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-end mt-1"},[(_vm.task.assignedTo.length)?_c('LimitElements',{staticClass:"v-avatar-group me-3",class:_vm.rootThemeClasses,attrs:{"elements":_vm.task.assignedTo,"limit":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg",attrs:{"size":"36"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'user-detail',
                  params: { id: data.user.id }
                })}}},'v-avatar',attrs,false),on),[(data.user.photo)?_c(VImg,{attrs:{"src":data.user.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(data.user.name)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.user.name))])])]}},{key:"others",fn:function(ref){
                var data = ref.data;
return [_c('div',{staticClass:"d-flex align-center mb-1",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'user-detail',
              params: { id: data.user.id }
            })}}},[_c(VAvatar,{staticClass:"v-avatar-light-bg mr-2",attrs:{"size":"20"}},[(data.user.photo)?_c(VImg,{attrs:{"src":data.user.photo}}):_c('span',{staticClass:"text-avatar-small"},[_vm._v(_vm._s(_vm.avatarText(data.user.name)))])],1),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(data.user.name))])],1)]}},{key:"others-activator",fn:function(ref){
            var data = ref.data;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"small":"","size":"36","color":"white primary--text text-caption"}},'v-avatar',attrs,false),on),[_c('span',[_vm._v("+"+_vm._s(data.limit))])])]}}],null,true)},[_c('span',[_vm._v("Lihat User Lain")])])]}}],null,false,1180404372)}):_vm._e(),(_vm.task.customer && !_vm.isJobProspectTask)?_c('div',{staticClass:"d-sm-flex align-center d-none cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
          name: 'customer-detail',
          params: { id: _vm.task.customer.id }
        })}}},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text mr-2",attrs:{"color":"primary","size":"36"}},[_vm._v(" "+_vm._s(_vm.avatarText(_vm.task.customer.name))+" ")]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-caption primary--text font-medium"},[_vm._v(_vm._s(_vm.task.customer.name))]),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(_vm.task.customer.phone_number))])])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column ml-auto align-end"},[(!_vm.isJobProspectTask)?_c('div',{staticClass:"d-flex mb-n1"},[(_vm.task.prospect)?_c('router-link',{staticClass:"text-caption primary--text",attrs:{"to":{ name: 'prospect-detail', params: { id: _vm.task.prospect.id } }}},[_vm._v(" "+_vm._s(_vm.task.prospect.name)+" ")]):_vm._e(),(_vm.task.prospect && _vm.task.job)?_c('span',{staticClass:"text-caption mx-1"},[_vm._v("/")]):_vm._e(),(_vm.task.job)?_c('router-link',{staticClass:"text-caption primary--text",attrs:{"to":{ name: 'job-detail', params: { id: _vm.task.job.id } }}},[_vm._v(" "+_vm._s(_vm.task.job.name)+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c(VSwitch,{staticClass:"ma-0",attrs:{"hide-details":"","dense":""},on:{"click":function($event){$event.stopPropagation();}}}),_c(VTooltip,{attrs:{"top":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"text--disabled",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.resolveIconVisibility(_vm.task.visibility.id))+" ")])]}}])},[_c('span',{staticClass:"d-block"},[_vm._v("Visibilitas: "+_vm._s(_vm.task.visibility.name))]),_c('span',[_vm._v(_vm._s(_vm.task.visibility.description))])]),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ms-1 text--disabled",attrs:{"size":"18"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('detail', _vm.task.id)}}},[_vm._v(" Lihat Detail ")]),(!_vm.isJobProspectTask && _vm.$can('update', 'Task'))?_c(VListItem,{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('update', _vm.task.id)}}},[_vm._v(" Ubah Task ")]):_vm._e(),(_vm.$can('del', 'Task'))?_c(VListItem,{staticClass:"error--text",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete', _vm.task.id)}}},[_vm._v(" Hapus Task ")]):_vm._e()],1)],1)],1)])],1)]),_c(VDivider)],1)}
var staticRenderFns = []

export { render, staticRenderFns }